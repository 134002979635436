import React from 'react'
import { Row, Col, Button, Card } from 'react-bootstrap'
import { Form, FormControl } from 'react-bootstrap'
import { Link, Outlet } from 'react-router-dom'
import {IoIosArrowBack} from 'react-icons/io'
const Newuser = () => {
    return (
        <div className='bg-light ' style={{ minHeight: '100vh' }}>
            <div className='container p-4 '>
                <Row>

                    <Col xs={12} sm={10}>
                        <h4><Link to="/loyalty" style={{ color: 'black' }}>
                                        <IoIosArrowBack />
                                    </Link>
                            <b className='mx-3' >New Program</b>
                        </h4>

                    </Col>

                </Row>
            </div>
            <Card className='p-3 container'>
                <div>
                    <h4>   <Link to="/createnewprogram" style={{ color: 'black' }}>
                                        <IoIosArrowBack />
                                    </Link> Add New User</h4>

                    <hr />
                </div>
                <Row>
                    <Col xs={12} sm={4}>
                        <Form>
                            <Form.Label>
                              Tier Code
                            </Form.Label>
                            <FormControl placeholder=" Enter Tier Code" />
                           
                            <Form.Label className='mt-3'>      Qualification Period</Form.Label>
                            <FormControl placeholder=" Enter Qualification Period" />
                        </Form>

                    </Col>
                    <Col xs={12} sm={4}>
                        <Form>
                        <Form.Label >       Tier Name</Form.Label>
                            <FormControl placeholder=" Enter Tier Name" />
                           
                            <Form.Label className='mt-3'>Tier Award Period</Form.Label>
                            <FormControl placeholder="Enter Tier Award Period" />
                        </Form>

                    </Col>
                    <Col xs={12} sm={4}>
                        <Form>
                            <Form.Label>
                                Previous Tier Code
                            </Form.Label>
                            <FormControl placeholder=" Enter Previous Tier Code" />
                           
                            <Form.Label className='mt-3'>  Next Tier Code</Form.Label>
                            <FormControl placeholder=" Enter Next Tier Code" />
                        </Form>

                    </Col>
                   
                </Row>
                <Row className='mt-4'>
                    <Col xs={12} sm={10} >

                    </Col>
                    <Col>
                        <Button variant="none" style={{ border: "solid lightgrey 1px" }}>Cancel</Button>
                        <Button className='ms-3'>Save</Button>
                    </Col>
                </Row>

            </Card>
           
        </div>

    )
}

export default Newuser