import React, { useState } from 'react';
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PointActivity from './PointActivity';
import Transactions from '../Transactions/Transactions';
import TransectionActivity from './TransectionActivity';









function Insights() {
  const navigate = useNavigate();


  function goToTransaction() {
    navigate("/");
  }

  const scrollableContainerStyle = {
    height: "80vh",  // Takes 80% of the viewport height
    maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px", // Optional: Add some padding if needed
    boxSizing: "border-box", // Ensure padding is included in height calculation
  };
  
  // In case you are using flexbox for your layout
  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full height of the viewport
  };

  return (
    <>
    <div style={containerWrapperStyle}>
       <div className='bg-light p-3 mb-5'  style={scrollableContainerStyle}>
       <div className='container '>
      <div className=''>
        <div className=' text-4xl font-bold pl-[20px] flex '>
          <div className='d-flex '>
            <BiChevronLeft size={40} onClick={()=>goToTransaction()} className='cursor-pointer' />
            <h4 className='mt-1'>
              <span>Invoices</span>
            </h4>
          </div>
        </div>
      </div>

      <div className='text-xl font-semibold flex mt-10  pb-3 '>
      <Tabs
    defaultActiveKey="Point Activity"
    id="uncontrolled-tab-example"
    className="mb-3 mt-2  fw-bold rounded"
    fill
    style={{background: '#FFF',
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"
    }}
   
  >
           <Tab  eventKey="Point Activity" title="Point Activity">
      <PointActivity />
    </Tab>
    <Tab eventKey="Transactions" title="Transactions">
      <TransectionActivity />
    </Tab>
      </Tabs>
      <svg className='w-100' xmlns="http://www.w3.org/2000/svg" width="1762" height="2" viewBox="0 0 1762 2" fill="none">
<path d="M1 1H1761" stroke="#DBDBDB" stroke-width="2" stroke-linecap="round"/>
</svg>
      </div>

      </div>
      </div>
      </div>
    </>
  );
}

export default Insights;
