// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/font/segoe/Segoe UI.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary: #0073E8;
  --secondary: #49516B;

  --secondary1: #E69C35;
  --secondary2: #C4D1FF;
  --secondary3: #D6E5FD;
  overflow: hidden;
}

@font-face {
  font-family: "Segoe UI";
  /*Can be any text*/
  src: local("Segoe UI"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

.search-container {
  border-bottom: 1px solid;
  width: 50%;
  padding-left: 15px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
  border-radius: 12px !important;
  /* border: none !important; */
}


 `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;;EAEpB,qBAAqB;EACrB,qBAAqB;EACrB,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB;8DAC4D;AAC9D;;AAEA;EACE,uEAAuE;AACzE;;AAEA;EACE,wBAAwB;EACxB,UAAU;EACV,kBAAkB;EAClB,oBAAoB;EACpB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,yDAAyD;EACzD,8BAA8B;EAC9B,6BAA6B;AAC/B","sourcesContent":[":root {\r\n  --primary: #0073E8;\r\n  --secondary: #49516B;\r\n\r\n  --secondary1: #E69C35;\r\n  --secondary2: #C4D1FF;\r\n  --secondary3: #D6E5FD;\r\n  overflow: hidden;\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Segoe UI\";\r\n  /*Can be any text*/\r\n  src: local(\"Segoe UI\"),\r\n    url(\"./assets/font/segoe/Segoe UI.ttf\") format(\"truetype\");\r\n}\r\n\r\nbody {\r\n  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;\r\n}\r\n\r\n.search-container {\r\n  border-bottom: 1px solid;\r\n  width: 50%;\r\n  padding-left: 15px;\r\n  padding-bottom: 10px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.card {\r\n  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;\r\n  border-radius: 12px !important;\r\n  /* border: none !important; */\r\n}\r\n\r\n\r\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
