// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.perc32 {
    font-weight: bold;
    margin-bottom: 12px;
    text-transform: uppercase;
}

.nme42 {
    font-size: 20px;
    font-weight: bolder;
}

.kjl43 {
    font-size: 12px;
    font-weight: 700;
}

.iyui8 {
    margin-bottom: 25px;
}

.kjio3 {
    font-weight: bolder;
}

.oiuio3 {
    font-size: 12px;
}

.iuioo3 {
    font-size: 21px;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/Components/Customer/memberEnrolment/index.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".perc32 {\r\n    font-weight: bold;\r\n    margin-bottom: 12px;\r\n    text-transform: uppercase;\r\n}\r\n\r\n.nme42 {\r\n    font-size: 20px;\r\n    font-weight: bolder;\r\n}\r\n\r\n.kjl43 {\r\n    font-size: 12px;\r\n    font-weight: 700;\r\n}\r\n\r\n.iyui8 {\r\n    margin-bottom: 25px;\r\n}\r\n\r\n.kjio3 {\r\n    font-weight: bolder;\r\n}\r\n\r\n.oiuio3 {\r\n    font-size: 12px;\r\n}\r\n\r\n.iuioo3 {\r\n    font-size: 21px;\r\n    font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
