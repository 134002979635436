import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown, Row, Col, Dropdown } from 'react-bootstrap';
import { BiLogOut } from "react-icons/bi";
import loyalty from "../../assets/images/Frame 2.png";
import profile from "../../assets/images/Profile.png";

const SubNavbar = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('home');

  const handleLinkClick = (link) => {
    setActiveLink(link);
    // Close the mobile menu when a link is clicked
    document.getElementById("navbar-toggler").click();
  };

  const isLinkActive = (link) => {
    return activeLink === link ? '#D1D3FF' : 'transparent';
  };

  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || 'home');
  }, [location.pathname]);

  const linkStyle = {
    textDecoration: 'none',
    color: "black",
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: isLinkActive('home'),
  };

  const navigate = useNavigate();

  function goToProfile() {
    navigate("/Profile");
    // Close the mobile menu when the profile is clicked
    document.getElementById("navbar-toggler").click();
  }

  function goToLogin() {
    navigate("/login");
    // Close the mobile menu when logout is clicked
    document.getElementById("navbar-toggler").click();
  }
  const styles = {
    color: 'var(--grey-700, #2F2F2F)',
    fontFamily: 'Noto Sans',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  };
  const userData = JSON.parse(localStorage.getItem("User_data"));
  const { full_name } = userData || {}; // Handle the case when userData is null
  
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container style={styles}>
        <Navbar.Brand href="#home">
          <img src={loyalty} className="m-3" alt="Loyalty" />
        </Navbar.Brand>
        <Navbar.Toggle id="navbar-toggler" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto bg-grey-700">
            <Nav.Link>
              <Link to={'/'} style={{ ...linkStyle, backgroundColor: isLinkActive('home') }} onClick={() => handleLinkClick('home')}>Home</Link>
            </Nav.Link>
            <Nav.Link>
              <Link to={'/InsightsComponent-app'} style={{ ...linkStyle, backgroundColor: isLinkActive('InsightsComponent-app') }} onClick={() => handleLinkClick('InsightsComponent-app')}>Insights</Link>
            </Nav.Link>
            <Nav.Link>
              <Link to={'/CustomerComponent-app'} style={{ ...linkStyle, backgroundColor: isLinkActive('CustomerComponent-app') }} onClick={() => handleLinkClick('CustomerComponent-app')}>Customers</Link>
            </Nav.Link>
            <Nav.Link>
              <Link to={'/transactions'} style={{ ...linkStyle, backgroundColor: isLinkActive('transactions') }} onClick={() => handleLinkClick('transactions')}>Transactions</Link>
            </Nav.Link>
            <Nav.Link>
              <Link to={'/loyalty'} style={{ ...linkStyle, backgroundColor: isLinkActive('loyalty') }} onClick={() => handleLinkClick('loyalty')}>Loyalty Program</Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Dropdown>
          <Dropdown.Toggle className="border-0" variant="none" id="dropdown-basic">
            <img src={profile} className="mt-2" alt="Profile" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-0 border-0 shadow-lg">
            <Dropdown.Item className="p-0">
              <Row>
                <div className="d-flex" onClick={() => goToProfile()}>
                  <Col className=""> <img src={profile} className="m-3" alt="Profile" /></Col>
                  <Col className="mt-3 m-2"
                    style={{
                      position: "relative",
                      left: "-10px"
                    }}> <h5 className="" style={{
                      color: 'var(--grey-700, #2F2F2F)',
                      fontFamily: 'Noto Sans',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal'
                    }} >{full_name}</h5>
                    <span className="" style={{
                      color: 'var(--grey-500, #6B6B6B)',
                      fontFamily: 'Noto Sans',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      position: "relative",
                      top: "-10px"
                    }}>Admin</span></Col>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="246" height="2" viewBox="0 0 246 2" fill="none">
                  <path d="M1 1L245 0.999979" stroke="#DBDBDB" stroke-linecap="round" />
                </svg></Row>
            </Dropdown.Item>
            <Dropdown.Item  onClick={() => goToLogin()}>
              <BiLogOut className="mx-2" style={{ color: '#DA4F4F' }} /><span className="" style={{
                color: 'var(--Error-main, #DA4F4F)',
                fontFamily: 'Noto Sans',
                fontSize: '19px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal'
              }} > Logout</span> </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Navbar>
  );
};

export default SubNavbar;








// import React, { useEffect, useState } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Nav, Col, Row } from 'react-bootstrap';
// import loyalty from "../../assets/images/Frame 2.png";
// import profile from "../../assets/images/Profile.png";
// import Dropdown from 'react-bootstrap/Dropdown';
// import { BiLogOut } from "react-icons/bi";

// const SubNavbar = () => {
//   const location = useLocation();
//   const [activeLink, setActiveLink] = useState('home');

//   const handleLinkClick = (link) => {
//     setActiveLink(link);
//   };

//   const isLinkActive = (link) => {
//     return activeLink === link ? '#D1D3FF' : 'transparent';
//   };

//   useEffect(() => {
//      const path = location.pathname.substring(1); 
//     setActiveLink(path || 'home');
//   }, [location.pathname]);


//   const linkStyle = {
//     textDecoration: 'none',
//     color: "black",
//     padding: '10px',
//     borderRadius: '5px',
//     backgroundColor: isLinkActive('home'),
//   };

//   const navigate = useNavigate()
//   function goToProfile(){
//     navigate("/Profile")
    
//   }
//   function goToLogin(){
//     navigate("/login")
    
//   }

  

 

//   return (
//     <div>
//       <div className="container">
//         <Row>
//           <Col xs={12} sm={2} className="mt-2">
//             <img src={loyalty} className="m-3" alt="Loyalty" />
//           </Col>
//           <Col xs={12} sm={9}>
//             <div>
//               <div className="d-flex">
//                 <Nav className=" mt-3 ">
//                   <Nav.Link className='me-1 mx-3 btnhover1 text-dark'>
//                     <Link to={'/'} style={{ ...linkStyle, backgroundColor: isLinkActive('home') }} onClick={() => handleLinkClick('home')}>Home</Link>
//                   </Nav.Link>
//                   <Nav.Link className='me-1 btnhover1 text-dark'>
//                     <Link to={'/InsightsComponent-app'} style={{ ...linkStyle, backgroundColor: isLinkActive('InsightsComponent-app') }} onClick={() => handleLinkClick('InsightsComponent-app')}>Insights</Link>
//                   </Nav.Link>
//                   <Nav.Link className='me-1 btnhover1 text-dark'>
//                   <Link to={'/CustomerComponent-app'} style={{ ...linkStyle, backgroundColor: isLinkActive('CustomerComponent-app') }} onClick={() => handleLinkClick('CustomerComponent-app')}>Customers</Link>
//                   </Nav.Link>
//                   <Nav.Link className='me-1 btnhover1 text-dark'>
//                     <Link to={'/transactions'} style={{ ...linkStyle, backgroundColor: isLinkActive('transactions') }} onClick={() => handleLinkClick('transactions')}>Transactions</Link>
//                   </Nav.Link>
//                   <Nav.Link className='me-1 btnhover1 text-dark'>
//                     <Link to={'/loyalty'} style={{ ...linkStyle, backgroundColor: isLinkActive('loyalty') }} onClick={() => handleLinkClick('loyalty')}>Loyalty Program</Link>
//                   </Nav.Link>
//                 </Nav>
//               </div>
//             </div>
//           </Col>
//           <Col className="p-2" xs={12} sm={1}>
//           <Dropdown>
//           <Dropdown.Toggle className="border-0" variant="none" id="dropdown-basic">
//             <img src={profile} className="mt-2" alt="Profile" />
//             </Dropdown.Toggle>
//             <Dropdown.Menu className="p-0 border-0 shadow-lg" >
//         <Dropdown.Item className="p-0">
//         <Row>
//         <div className="d-flex" onClick={()=>goToProfile()}>  <Col className=""> <img src={profile} className="m-3" alt="Profile" /></Col> 
//           <Col  className="mt-3 m-2" 
//           style={{ position: "relative",
//           left: "-10px"}}> <h5 className="" style={{ color: 'var(--grey-700, #2F2F2F)',
//   fontFamily: 'Noto Sans',
//   fontSize: '20px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: 'normal'}} >Soyam Ali</h5>
//           <span className="" style={{  color: 'var(--grey-500, #6B6B6B)',
//   fontFamily: 'Noto Sans',
//   fontSize: '16px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: 'normal',
//   position: "relative",
//   top: "-10px"}}>Admin</span></Col> 
//          </div>
//          <svg xmlns="http://www.w3.org/2000/svg" width="246" height="2" viewBox="0 0 246 2" fill="none">
// <path d="M1 1L245 0.999979" stroke="#DBDBDB" stroke-linecap="round"/>
// </svg></Row> 
//          </Dropdown.Item>
//         <Dropdown.Item href="#/action-2">
//         <BiLogOut className="mx-2" style={{ color: '#DA4F4F' }} /><span className="" style={{  color: 'var(--Error-main, #DA4F4F)',
//   fontFamily: 'Noto Sans',
//   fontSize: '19px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: 'normal'}} onClick={()=>goToLogin()}> Logout</span> </Dropdown.Item>
      
//       </Dropdown.Menu>
//     </Dropdown>

//           </Col>
//         </Row>
//       </div>

//       {/* Rest of your component */}
//     </div>
//   );
// };

// export default SubNavbar;
