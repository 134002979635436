import React, { useState } from 'react'
import { BiChevronLeft } from 'react-icons/bi';
import { Row, Col, Button, Card, FormGroup } from 'react-bootstrap'
import { Form, FormControl } from 'react-bootstrap'
import { Link, Outlet } from 'react-router-dom'
import profile from "../../assets/images/Profile.png";
import { Input, Label } from 'reactstrap';
import { Select } from '@mui/material';
import PasswordChangeModal from './PasswordChangeModal';
function index() {
  const inlineStyles = {
    color: "var(--grey-700, #2F2F2F)",
    fontFamily: "Noto Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };
  const [modalCheckPrice, setCheckModalPrice] = useState(false);

  const { full_name, email,phone_number} = JSON.parse(localStorage.getItem("User_data"));



  const scrollableContainerStyle = {
    height: "80vh",  // Takes 80% of the viewport height
    maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px", // Optional: Add some padding if needed
    boxSizing: "border-box", // Ensure padding is included in height calculation
  };
  
  // In case you are using flexbox for your layout
  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full height of the viewport
  };
  return (
    <>
    <div style={containerWrapperStyle}>
    <div className="bg-light p-3" style={scrollableContainerStyle}>
    <div className=' container' style={{ minHeight: 'fit-content' }}> 
    <div className=' text-4xl font-bold pl-[20px] flex '>
    <div className='d-flex '>
      <BiChevronLeft size={40} className='' />
      <h4 className='mt-1'>
      <span className=""  style={{inlineStyles}}>Profile</span>
      </h4>
    </div>
  </div>


  <div className='bg-white mt-3'
                      style={{  overflowX: "auto",
                      boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                      borderRadius: "0.75rem",
                      overflow: "hidden",
                      fontFamily: "DM sans-serif",
                      padding: "20px",}}>
                        <div>
                            <h4> Basic Details</h4>

                            <h6  className='float-end'  style={{ marginTop: '-30px' ,
                            color: 'var(--Primary-1, #565ADD)',
                            textAlign: 'right',
                            fontFamily: 'Noto Sans',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: 'normal', cursor:"pointer"}} onClick={() => setCheckModalPrice(true)} >
                             Change Password</h6>
                            <hr />
                        </div>
                        <div>
                            <Row>
                                <Col md='2' sm='6'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="151" height="151" viewBox="0 0 161 161" fill="none">
<circle cx="80.5" cy="80.5" r="80.5" fill="#328CDF"/>
<path d="M105.75 111.167H94.955V84.433H68.4744V111.167H57.6797V50.9526H68.4744V75.2406H94.955V50.9526H105.75V111.167Z" fill="white"/>
</svg>
                                </Col>
                                <Col md='8' className='mt-3' sm='12'>
                                    <h6 className='m-0' style={{ color: 'var(--grey-500, #6B6B6B)',
  fontFamily: 'Noto Sans',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',}}> Full Name</h6>
                                    <h5 style={{ 
  
  color: 'var(--Grey-900, #2C2C2C)',
  fontFamily: 'Noto Sans',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',}}> {full_name}</h5>
  <Row>
    <Col className='jNIkof' md='5' sm='12'>
     <span style={{ color: 'var(--grey-500, #6B6B6B)',
  fontFamily: 'Noto Sans',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',}}> Email</span>
                                    <span style={{ 
  
  color: 'var(--Grey-900, #2C2C2C)',
  fontFamily: 'Noto Sans',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',}}>{email}</span>
                                </Col>


                                <Col className='mt-2' md='10' sm='12'>
                                    <h6 className='m-0' style={{ color: 'var(--grey-500, #6B6B6B)',
  fontFamily: 'Noto Sans',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',}}> Phone Number</h6>
                                    <span style={{ 
  
  color: 'var(--Grey-900, #2C2C2C)',
  fontFamily: 'Noto Sans',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',}}>+91{phone_number}</span>
                                </Col></Row>
                                </Col>


                            </Row>
                        </div>
                      
                    </div>



                    <div className=" bg-white   container  mt-4 "
      style={{  overflowX: "auto",
      boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
      borderRadius: "0.75rem",
      overflow: "hidden",
      fontFamily: "DM sans-serif",
      padding: "20px",}}>
                <div>
                    <h4> Company Details</h4>

                    <hr />
                </div>
                <Row>
                    <Col xs={12} sm={6}>
                        <Form>
                            <Form.Label>
                            Registered Business Name
                            </Form.Label>
                            <FormControl placeholder="Soyam ali" />
                         
                              <Form.Label className='mt-3'>
                              Company LinkedIn Profile
                            </Form.Label>
                            <FormControl placeholder="Soyam ali " />

                        </Form>

                    </Col>
                    <Col>
                        <Form>
                            <Form.Label>
                            Company Website
                            </Form.Label>
                            <FormControl placeholder="alibhai@example.com" />

                            <Form.Group >
                                <Form.Label className='mt-3'>Rewarding Currency</Form.Label>
                                <Form.Select>
                                    <option value="option1">AED</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                    <option value="option4">Option 4</option>
                                </Form.Select>
                            </Form.Group> 
                        </Form>
                    </Col>
                </Row>
                <Row className='mt-4'>
            <Col  md={12}>
              <div className=""><h4>Address Info</h4></div>
            </Col>
            <hr className=""/>
          
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Address Line 1
                      {/* <span className="text-red"> * </span> */}
                    </Label>
                    <Input
                      type="text"
                    
                      // required={true}
                      placeholder="Enter Address Line 1"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Address Line 2
                      {/* <span className="text-red"> * </span> */}
                    </Label>
                    <Input
                      type="text"
                     
                      // required={true}
                      placeholder="Enter Address Line 2"
                    />
                  </FormGroup>
                </Col>
          
                <Col className="mt-2" md={3}>
                  <FormGroup>
                    <Label>
                      State
                      {/* <span className="text-red"> * </span> */}
                    </Label>
                    <input
                      type="text"
                      className="form-control"
                     
                      // required={true}
                      placeholder="Select State"
                    />
                  </FormGroup>
                </Col>

                <Col className="mt-2" md={3}>
                  <FormGroup>
                    <Label>
                      City
                      {/* <span className="text-red"> * </span> */}
                    </Label>
                    <input
                      type="text"
                      className="form-control"
                     
                      // required={true}
                      placeholder="Select City"
                    />
                  </FormGroup>
                </Col>

                <Col className="mt-2" md={3}>
                  <FormGroup>
                    <Label>
                      Country <span className="text-red"> * </span>
                    </Label>
                    <input
                      type="text"
                      className="form-control"
                     
                      placeholder="Select Country"
                    />
                  </FormGroup>
                </Col>

                <Col className="mt-2" md={3}>
                  <FormGroup>
                    <Label>
                      Postal Code
                      {/* <span className="text-red"> * </span> */}
                    </Label>
                    <Input
                      type="text"
                     
                      placeholder="Enter Postal Code"
                    />
                  </FormGroup>
                </Col>

            
           
            

        

          
          </Row>
          <Row className='mt-4'>
            <Col  md={12}>
              <div className=""><h4>Registration Details</h4></div>
            </Col>
            <hr className=""/>
          
                <Col md={6}>
                  <FormGroup>
                    <Label>
                    Registration Identification Number
                      {/* <span className="text-red"> * </span> */}
                    </Label>
                    <Input
                      type="text"
                    
                      // required={true}
                      placeholder="Eg. GST, TAX ID, VAT, etc."
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                    Confirm Registration Identification Number
                      {/* <span className="text-red"> * </span> */}
                    </Label>
                    <Input
                      type="text"
                     
                      // required={true}
                      placeholder="Re-enter Registered Identification Number"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                <Form.Group >
                                <Form.Label className='mt-3'> Country of Registration</Form.Label>
                                <Form.Select>
                                    <option value="option1">India</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                    <option value="option4">Option 4</option>
                                </Form.Select>
                            </Form.Group> 
                
                </Col>
                    <Col className="mt-4" md={12}>
              <div className="mt-2 d-flex justify-content-end">
                <Button
                  type="button"
                  className="lightButton me-2"
                  style={{  borderRadius: '10px',
                  border: '1px solid var(--grey-500, #6B6B6B)',
                  boxShadow: '0px 3px 13px 0px rgba(57, 57, 57, 0.07)'}}
                 
                >
                  Reset
                </Button>

                <Button
                  type="submit"
                  style={{  borderRadius: '10px',
                  background: 'var(--Primary-1, #565ADD)',
                  boxShadow: '0px 3px 13px 0px rgba(57, 57, 57, 0.07)'}}
                >
                 Submit for Verification
                </Button>
              </div>
            </Col>
                </Row>
              
            </div>
  </div>

 
  </div></div>
  <PasswordChangeModal show={modalCheckPrice} onHide={() => setCheckModalPrice(false)} />
  </>
  )
}

export default index