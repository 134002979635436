import React, { useState } from 'react';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';
import Swal from 'sweetalert2';
import DataService from "../../Components/services/requestApi";

const Policies = ({ program ,program_id}) => {
    // State to manage the form data
    const [formData, setFormData] = useState({
        qualification: program?.qualification || '',
        qualifyingEntries: program?.qualifying_entries || '',
        pointAccrualFactor: program?.point_accrual_factor || '',
        pointRedemptionFactor: program?.point_redemption_factor || '',
        pointExpirationPolicy: program?.point_expiration_policy || '',
        pointExpirationPeriod: program?.point_expiration_period || ''
    });

    // Handle input change for form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const { client_id } = JSON.parse(localStorage.getItem("User_data"));
     const handleEnterClick = async () => {
    try {
      const data = {
        qualification: formData.qualification,
        qualifying_entries: formData.qualifyingEntries,
        point_accrual_factor: formData.pointAccrualFactor,
        point_redemption_factor: formData.pointRedemptionFactor,
        point_expiration_policy: formData.pointExpirationPolicy,
        point_expiration_period: formData.pointExpirationPeriod
      };
      const response = await DataService.updatePolicesByCode(client_id, program_id, data);
        Swal.fire({
          title: response.data.message,
          icon: "success",
          timer: 1500,
        });
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

    return (
        <div>
            <div className="bg-white container mb-3"
                style={{
                    overflowX: "auto",
                    boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                    borderRadius: "0.75rem",
                    overflow: "hidden",
                    fontFamily: "DM sans-serif",
                    padding: "20px",
                }}>
                <div>
                    <h4> Policies</h4>
                    <hr />
                </div>
                <Row>
                    <Col xs={12} sm={6}>
                        <Form>
                            <Form.Group>
                                <Form.Label>Qualification</Form.Label>
                                <Form.Select
                                    name="qualification"
                                    value={formData.qualification}
                                    onChange={handleChange}
                                >
                                    <option value="amount">Amount</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                    <option value="option4">Option 4</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Label className='mt-3'>Point Accrual Factor</Form.Label>
                            <FormControl
                                name="pointAccrualFactor"
                                value={formData.pointAccrualFactor}
                                onChange={handleChange}
                                placeholder="0"
                            />
                            <Form.Group>
                                <Form.Label className='mt-3'>Point Expiration Policy</Form.Label>
                                <Form.Select
                                    name="pointExpirationPolicy"
                                    value={formData.pointExpirationPolicy}
                                    onChange={handleChange}
                                >
                                    <option value="option1">No Expiration</option>
                                    <option value="option2">1 Year</option>
                                    <option value="option2">2 Year</option>
                                    <option value="option3">3 Year</option>
                                    <option value="option4">4 Year</option>
                                </Form.Select>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col>
                        <Form>
                            <Form.Label>Qualifying Entries</Form.Label>
                            <FormControl
                                name="qualifyingEntries"
                                value={formData.qualifyingEntries}
                                onChange={handleChange}
                                placeholder="0"
                            />
                            <Form.Label className='mt-3'>Point Redemption Factor</Form.Label>
                            <FormControl
                                name="pointRedemptionFactor"
                                value={formData.pointRedemptionFactor}
                                onChange={handleChange}
                                placeholder="0"
                            />
                            <Form.Group>
                                <Form.Label className='mt-3'>Point Expiration Period</Form.Label>
                                <Form.Select
                                    name="pointExpirationPeriod"
                                    value={formData.pointExpirationPeriod}
                                    onChange={handleChange}
                                >
                                    <option value="option1">1 year</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                    <option value="option4">Option 4</option>
                                </Form.Select>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={10}></Col>
                    <Col className='mt-3'>
                        <Button variant="none" style={{ border: "solid lightgrey 1px" }}>Cancel</Button>
                        <Button className='ms-3' onClick={handleEnterClick} style={{ borderRadius: '10px', background: 'var(--Primary-1, #565ADD)', boxShadow: '0px 3px 13px 0px rgba(57, 57, 57, 0.07)' }}>Save</Button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Policies;
