import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Label, Input, Form, Button } from "reactstrap";
import { BiChevronDown, BiChevronLeft } from "react-icons/bi";
import Select, { useStateManager } from "react-select";
import Flatpickr from "react-flatpickr";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { BACKEND_URL } from "../../utils/constant";


const obj = {
  title: "",
  customer_name: "",
  gender: "",
  dob: "",
  language: "",
  nationality: "",
  marital_status: "",
  anniversary_date: "",
  location: "",
  address_line1: "",
  address_line2: "",
  state: "",
  city: "",
  base_currency: "",
  country: "",
  pincode: "",
  phone: "",
  email: "",
};
const CustomerOverview = () => {
   const location = useLocation();
  const userData = location.state && location.state.userData;
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(obj)));
  const { country_code_dropdown, to_dropdown, suffix, gender_dropdown, nationality_dropdown, base_currency,location_dropdown, language_dropdown, marital_status_dropdown } = useSelector(e => e.ComponentPropsManagement)

  const [countryCode, setCountryCode] = useState("");
  const handleReset = () => {
    setCountryCode("");
    setFormData(JSON.parse(JSON.stringify(obj)));
  };
  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = JSON.parse(JSON.stringify(formData));
    // data.phone = `${countryCode}${data.phone}`;
    console.log("data", data);

    axios
      .put(
        `${BACKEND_URL}/loyalty/update-customer/${formData.loyalty_id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.request.status) {
          Swal.fire({
            title: "Data Updated SuccesFully",
            icon: "success",
            timer: 1500,
          });
          navigate('/CustomerComponent-app')
        }
      })
      .then((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userData) {
      setFormData(userData);
      // console.log("INSIDE OVERVIEW", userData);
    }
  }, [userData]);



  
  //   console.log(URL);
  const inlineStyles = {
    color: "var(--grey-700, #2F2F2F)",
    fontFamily: "Noto Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };

 
  function goToCustomer(){
    const loyaltyId = formData.loyalty_id;
    navigate(`/InvoiceCustomer/${loyaltyId}`);
    
  }



  const handleGoBack = () => {
    navigate("/CustomerComponent-app"); // This function navigates back in the history stack
  };



  const scrollableContainerStyle = {
    height: "80vh",  // Takes 80% of the viewport height
    maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px", // Optional: Add some padding if needed
    boxSizing: "border-box", // Ensure padding is included in height calculation
  };
  
  // In case you are using flexbox for your layout
  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full height of the viewport
  };
  return (
    <>
   
   <div style={containerWrapperStyle}>
   <div className="bg-light p-3" style={scrollableContainerStyle}>
 <div className=' container' style={{ minHeight: 'fit-content' }}> 
<div className='container'>
        <div className="p-3 d-flex ">
        <BiChevronLeft size={35} className='' onClick={handleGoBack} />
<Row className="">
      <Col md={11}>
      <h4>
            <span className="m-3"  style={{inlineStyles}}>Customer Overview</span>
          </h4>
        </Col>
        <Col xs={12} sm={1} >
            <Button className='text-nowrap' style={{  borderRadius: '10px',marginLeft:"150px",
  background: 'var(--Primary-1, #565ADD)',
  boxShadow: '0px 3px 13px 0px rgba(57, 57, 57, 0.07)',
  display: 'inline-flex',
  padding: '9px 37px 9px 38px',
  justifyContent: 'center',
  alignItems: 'center',}} 
  onClick={()=>goToCustomer()}>Invoices</Button>
          </Col>
        </Row>
     
        </div>
      </div >
      <Row className="">
      <Col md={4}>
      <div class="card">
      <div class="card-body">
        <h5 class="card-title" style={{inlineStyles}}>{formData.customer_name}</h5>
        <hr/>
        <p style={{ color: 'var(--grey-700, #2F2F2F)',
  fontFamily: 'Noto Sans',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'}}>Program : Loyalty Programme.</p>
        <p style={{ color: 'var(--grey-700, #2F2F2F)',
  fontFamily: 'Noto Sans',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'}}>Enrollment Status : {formData.customer_status}</p>

      
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <span style={{ color: 'var(--grey-700, #2F2F2F)',
  fontFamily: 'Noto Sans',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'}}>Balance Amount</span>
        <h5 class="card-title" style={{inlineStyles}}>{formData.balance_amount}</h5>
        <hr/>
        <span style={{ color: 'var(--grey-700, #2F2F2F)',
  fontFamily: 'Noto Sans',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'}}>Card Number</span>
 <h5 class="card-title" style={{inlineStyles}}>{formData.loyalty_id}</h5>
 <hr/>
 <span style={{ color: 'var(--grey-700, #2F2F2F)',
  fontFamily: 'Noto Sans',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'}}>Base Currency</span>
 <h5 class="card-title" style={{inlineStyles}}>{formData.base_currency}</h5>
      </div>
    </div>  </Col>
      <Col md={8} className="text-nowrap">
      <div className="container bg-white " 
         style={{  overflowX: "auto",
         boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
         borderRadius: "10px",
         overflow: "hidden",
         fontFamily: "DM sans-serif",
         padding: "20px",}}>
        <Form onSubmit={handleSubmit} className="">
          <Row className="">
            
            <Col md={12}>
              <div className="perc32">PERSONAL INFO</div>
            </Col>
            <hr/>
            <Col md={3}>
              <FormGroup>
                <Label>
                  Title <span className="text-red"> * </span>
                </Label>
                <Select
                  options={to_dropdown}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, suffix: val });
                  }}
                  // value={formData.suffix}
                  required={true}
                  placeholder="Select Mr."
                />
              </FormGroup>
            </Col>


            <Col md={3}>
              <FormGroup>
                <Label>
                  Customer Name <span className="text-red"> * </span>
                </Label>
                <Input
                  type="text"
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormData({ ...formData, customer_name: val });
                  }}
                  value={formData.customer_name}
                  required={true}
                  placeholder="Enter Customer Name"
                />
              </FormGroup>
            </Col>

          

            {/* <Col md={3}>
              <FormGroup>
                <Label>
                  Client ID
                  <span className="text-red"> * </span>
                </Label>
                <h3>{loginData?.client_id}</h3>
              </FormGroup>
            </Col> */}

<Col md={3}>
              <FormGroup>
                <Label>
                  DOB <span className="text-red"> * </span>
                </Label>
                <Flatpickr
                  className="form-control"
                  options={{ dateFormat: "d-M-Y", allowInput: true }}
                  onChange={(date) => {
                    const val = date[0];
                    setFormData({ ...formData, dob: val });
                  }}
                  value={formData.dob}
                  required={true}
                  placeholder="Select Birth Date"
                />
              </FormGroup>
            </Col>


            <Col md={3}>
              <FormGroup>
                <Label>
                  Gender <span className="text-red"> * </span>
                </Label>
                <Select
                  options={gender_dropdown}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, gender: val });
                  }}
                  // value={formData.gender}
                  required={true}
                  placeholder="Select Gender"
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>
                  Nationality <span className="text-red"> * </span>
                </Label>
                <Select
                  options={[
                    { label: "Indian", value: "Indian" },
                    { label: "Austarlia", value: "Austarlia" },
                  ]}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, language: val });
                  }}
                  //   value={formData.language}
                  required={true}
                  placeholder="Nationality"
                />
                {/* <Select
                  options={[]}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, nationality: val });
                  }}
                  value={formData.nationality}
                  required={true}
                  placeholder="Select Nationality"
                /> */}
              </FormGroup>
            </Col>

          


            <Col md={3}>
              <FormGroup>
                <Label>
                  Language <span className="text-red"> * </span>
                </Label>
                <Select
                  options={[
                    { label: "English", value: "English" },
                    { label: "Hindi", value: "Hindi" },
                  ]}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, language: val });
                  }}
                  //   value={formData.language}
                  required={true}
                  placeholder="Select Language"
                />
              </FormGroup>
            </Col>
            
            <Col md={3}>
              <FormGroup>
                <Label>
                  Base Currency <span className="text-red"> * </span>
                </Label>
                <Select
                  type="text"
                  options={base_currency}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, base_currency: val });
                  }}
                  value={base_currency.filter(io => io.value === formData.base_currency)}
                  required={true}
                  placeholder="Select Base Currency"
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>
                  Marital Status <span className="text-red"> * </span>
                </Label>
                <Select
                  options={marital_status_dropdown}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, language: val });
                  }}
                  //   value={formData.language}
                  required={true}
                  placeholder="Select Marital Status"
                />
                {/* <Select
                  options={[]}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, marital_status: val });
                  }}
                  value={formData.marital_status}
                  required={true}
                  placeholder=" Marital Status"
                /> */}
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>
                  Anniversary Date <span className="text-red"> * </span>
                </Label>
                <Flatpickr
                  className="form-control"
                  options={{ dateFormat: "d-M-Y", allowInput: true }}
                  onChange={(date) => {
                    const val = date[0];
                    setFormData({ ...formData, anniversary_date: val });
                  }}
                  value={formData.anniversary_date}
                  required={true}
                  placeholder="Select Anniversary Date"
                />
              </FormGroup>
            </Col>

            <Col md={5}>
              <FormGroup>
                <Label>
                  Phone <span className="text-red"> * </span>
                </Label>
                <div className="d-flex">
                  <div className="me-2 w-75">
                    <Select
                      // className="form-control"
                      // type="text"
                      options={country_code_dropdown}
                      onChange={(e) => {
                        const val = e.value;
                        setCountryCode(val);
                      }}
                      value={country_code_dropdown.filter(io => io.value === countryCode)}
                      required={true}
                      placeholder="Country Code"
                    />
                  </div>
                  <div className="w-100">
                    <Input
                      type="text"
                      onChange={(e) => {
                        const val = e.target.value;
                        setFormData({ ...formData, mobile_number: val });
                      }}
                      value={formData.mobile_number}
                      required={true}
                      placeholder="Enter Phone Number"
                    />
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>
                  Email <span className="text-red"> * </span>
                </Label>
                <Input
                  type="text"
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormData({ ...formData, email_id: val });
                  }}
                  value={formData.email_id}
                  required={true}
                  placeholder="Enter Email"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="perc32">Address Info</div>
            </Col>
            <hr className=""/>
            <Col md={4}>
                  <FormGroup>
                    <Label>
                      Location
                      {/* <span className="text-red"> * </span> */}
                    </Label>
                    <Input
                      type="text"
                      onChange={(e) => {
                        const val = e.target.value;
                        formData.location = val;
                        setFormData({ ...formData });
                      }}
                      value={formData.location}
                      // required={true}
                      placeholder="Enter Location"
                    />
                  </FormGroup>
                  
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>
                      Address Line 1
                      {/* <span className="text-red"> * </span> */}
                    </Label>
                    <Input
                      type="text"
                      onChange={(e) => {
                        const val = e.target.value;
                        formData.address_line1 = val;
                        setFormData({ ...formData });
                      }}
                      value={formData.address_line1}
                      // required={true}
                      placeholder="Enter Address Line 1"
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>
                      Address Line 2
                      {/* <span className="text-red"> * </span> */}
                    </Label>
                    <Input
                      type="text"
                      onChange={(e) => {
                        const val = e.target.value;
                        formData.address_line2 = val;
                        setFormData({ ...formData });
                      }}
                      value={formData.address_line2}
                      // required={true}
                      placeholder="Enter Address Line 2"
                    />
                  </FormGroup>
                </Col>
                {/* <Col md={3}>
              <FormGroup>
                <Label>
                  Location <span className="text-red"> * </span>
                </Label>
                <Select
                  options={location_dropdown}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, location: val });
                  }}
                  value={location_dropdown.filter(io => io.value === formData.location)}
                  required={true}
                  placeholder="Enter Location"
                />
              </FormGroup>
            </Col> */}

                <Col md={3}>
                  <FormGroup>
                    <Label>
                      State
                      {/* <span className="text-red"> * </span> */}
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        const val = e.value;
                        formData.state = val;
                        setFormData({ ...formData });
                      }}
                      value={formData.city}
                      // required={true}
                      placeholder="Select State"
                    />
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup>
                    <Label>
                      City
                      {/* <span className="text-red"> * </span> */}
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        const val = e.value;
                        formData.city = val;
                        setFormData({ ...formData });
                      }}
                      value={formData.city}
                      // required={true}
                      placeholder="Select City"
                    />
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup>
                    <Label>
                      Country <span className="text-red"> * </span>
                    </Label>
                  
                      <Input
                  type="text"
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormData({ ...formData, country: val });
                  }}
                  value={formData.country}
                  required={true}
                  placeholder="Select Country"
                />
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup>
                    <Label>
                      Postal Code
                      {/* <span className="text-red"> * </span> */}
                    </Label>
                 
                      <Input
                  type="number"
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormData({ ...formData, pincode: val });
                  }}
                  value={formData.pincode}
                  required={true}
                  placeholder="Enter Postal Code"
                />
                  </FormGroup>
                </Col>

            
           
            {/* <Col md={3}>
              <FormGroup>
                <Label>
                  Business Created Date <span className="text-red"> * </span>
                </Label>
                <Flatpickr
                  className="form-control"
                  options={{ dateFormat: "d-M-Y", allowInput: true }}
                  onChange={(date) => {
                    const val = date[0];
                    setFormData({ ...formData, business_created_date: val });
                  }}
                  value={formData.business_created_date}
                  required={true}
                  placeholder="Select Business Created Date"
                />
              </FormGroup>
            </Col> */}

        

            <Col className="mt-4" md={8}>
              <div className="mt-2 d-flex justify-content-end">
                <Button
                  type="button"
                  className="lightButton me-2"
                  style={{  borderRadius: '10px',
                  border: '1px solid var(--grey-500, #6B6B6B)',
                  boxShadow: '0px 3px 13px 0px rgba(57, 57, 57, 0.07)'}}
                  onClick={() => handleReset()}
                >
                  Reset
                </Button>

                <Button
                  type="submit"
                  style={{  borderRadius: '10px',
                  background: 'var(--Primary-1, #565ADD)',
                  boxShadow: '0px 3px 13px 0px rgba(57, 57, 57, 0.07)'}}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div> </Col> </Row>
     
      </div>
      </div>
      </div>
    </>
  );
};

export default CustomerOverview;
