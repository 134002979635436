import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {useNavigate} from 'react-router-dom'



const initialComponentPropsManagementState = {
  load: false,
  switchTab: "insights",
  login_data: "",
  all_customers: [],
  all_invoices: [],
  all_point_activity_graph: "",
  all_transaction_activity_graph: "",

  country_code_dropdown: [
    { label: "+91 (India)", value: "+91" },
    { label: "+971 (UAE)", value: "+971" },
    { label: "+973 (Bahrain)", value: "+973" },
    { label: "+1 (United States)", value: "+1" },
    { label: "+44 (United Kingdom)", value: "+44" },
    { label: "+61 (Australia)", value: "+61" },
    { label: "+86 (China)", value: "+86" },
    { label: "+82 (South Korea)", value: "+82" },
    { label: "+81 (Japan)", value: "+81" },
    { label: "+92 (Pakistan)", value: "+92" },
    { label: "+880 (Bangladesh)", value: "+880" },
    { label: "+234 (Nigeria)", value: "+234" },
  ],
  language_dropdown: [],
  to_dropdown: [
    { label: "MR.", value: "Mr." },
    { label: "MRS.", value: "Mrs." },
  ],
  gender_dropdown: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ],
  nationality_dropdown: [    { label: "Indian", value: "Indian" },
  { label: "USA", value: "USA" },
  { label: "British", value: "British" },
  { label: "Australian", value: "Australian" },
  { label: "Canadian", value: "Canadian" },
  { label: "Chinese", value: "Chinese" },
  { label: "Japanese", value: "Japanese" },
  { label: "Russian", value: "Russian" },
  { label: "German", value: "German" },
  { label: "French", value: "French" },
  { label: "Italian", value: "Italian" },
  { label: "Spanish", value: "Spanish" },
  { label: "Mexican", value: "Mexican" },
  { label: "Brazilian", value: "Brazilian" },
  { label: "Argentinian", value: "Argentinian" },],
  location_dropdown: [
    { label: "Ambedkar nagar", value: "Ambedkar nagar" },
    { label: "Mochiwara", value: "Mochiwara" },
  ],
  marital_status_dropdown: [
    { label: "Single", value: "1" },
    { label: "Married", value: "2" },
  ],
  city_dropdown: [
    { label: "Sikar", value: "Sikar" },
    { label: "Fathepur", value: "Fathepur" },
  ],
  country_dropdown:[
    { label: "India", value: "India" },
    { label: "Australia", value: "Australia" },
    { label: "United States", value: "United States" },
    { label: "United Kingdom", value: "United Kingdom" },
    { label: "Canada", value: "Canada" },
    { label: "China", value: "China" },
    { label: "Japan", value: "Japan" },
    { label: "Germany", value: "Germany" },
    { label: "France", value: "France" },
    { label: "Italy", value: "Italy" },
    { label: "Spain", value: "Spain" },
    { label: "Mexico", value: "Mexico" },
    { label: "Brazil", value: "Brazil" },
    { label: "Argentina", value: "Argentina" },
  ],
  base_currency: [   { label: "INR", value: "INR" },
  { label: "AED", value: "AED" },
  { label: "SAR", value: "SAR" },
  { label: "BHD", value: "BHD" },
  { label: "OMR", value: "OMR" },
  { label: "KWD", value: "KWD" },
  { label: "QAR", value: "QAR" },
  { label: "EGP", value: "EGP" },
  { label: "USD", value: "USD" }],
};

export const ComponentPropsManagement = createSlice({
  name: "ComponentPropsManagement",

  initialState: initialComponentPropsManagementState,
  reducers: {
    handleChaneSwitchTab: (state, payload) => {
      state.switchTab = payload.payload;
    },
    handleLoginRequest: (state, payload) => {
      state.load = true;
    },
    handleLoginResponse: (state, payload) => {
      state.login_data = payload.data;
      state.load = false;
    },
    handleGetUserData: (state, payload) => {
      state.login_data = JSON.parse(payload.payload);
    },
    handleLogout: (state, payload) => {
      state.login_data = {};
      localStorage.removeItem("client_id");
      localStorage.removeItem("login_data");
      // location.replace("/login");
    },
    handleAllCustomerDataRequest: (state, payload) => {
      state.load = true;
    },
    handleAllCustomerDataResponse: (state, payload) => {
      state.load = false;
      state.all_customers = payload.data;
    },
    handleAllInvoicesDataRequest: (state, payload) => {
      state.load = true;
    },
    handleAllInvoicesDataResponse: (state, payload) => {
      state.load = false;
      state.all_invoices = payload.data;
    },
    handleAllPointActivityAPIDataRequest: (state, payload) => {
      // console.log("PAYLOAD", payload);
      state.load = true;
    },
    handleAllPointActivityAPIResponse: (state, payload) => {
      state.load = false;
      state.all_point_activity_graph = payload.data;
    },
    //Transaction Activity Graph's API
    handleAllTransactionActivityAPIDataRequest: (state, payload) => {
      // console.log(payload, "Payload in all Data");
      state.load = true;
    },
    handleAllTransactionActivityAPIResponse: (state, payload) => {
      // console.log("RESPONSE", payloa\d);
      state.load = false;
      state.all_transaction_activity_graph = payload.data;
    },
    handleGetLanguageRequest: (state, payload) => {
      state.load = true;
    },
    handleGetLanguageResponse: (state, payload) => {
      state.language_dropdown = payload.data;
      state.load = false;
    },
    handleGetNationalityRequest: (state, payload) => {
      state.load = true;
    },
    handleGetNationalityResponse: (state, payload) => {
      state.nationality_dropdown = payload.data;
      state.load = false;
    },
    handleCityDetailDropdownRequest: (state, payload) => {
      state.load = true;
    },
    handleCityDetailDropdownResponse: (state, payload) => {
      state.city_dropdown = payload.data;
      state.load = false;
    },
    handleLocationDetailDropdownRequest: (state, payload) => {
      state.load = true;
    },
    handleLocationDetailDropdownResponse: (state, payload) => {
      state.location_dropdown = payload.data;
      state.load = false;
    },
    handleGetCountryDropdownRequest: (state, payload) => {
      state.load = true;
    },
    handleGetCountryDropdownResponse: (state, payload) => {
      state.country_dropdown = payload.data;
      state.load = false;
    },
    handleBaseCurrencyDropdownRequest: (state, payload) => {
      state.load = true;
    },
    handleBaseCurrencyDropdownResponse: (state, payload) => {
      state.base_currency = payload.data;
      state.load = false;
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  handleAllCustomerDataResponse,
  handleChaneSwitchTab,
  handleLoginRequest,
  handleLogout,
  handleGetUserData,
  handleAllCustomerDataRequest,
  handleAllInvoicesDataRequest,
  handleAllPointActivityAPIDataRequest,
  handleAllTransactionActivityAPIDataRequest,
  handleGetLanguageRequest,
  handleGetNationalityRequest,
  handleCityDetailDropdownRequest,
  handleLocationDetailDropdownRequest,
  handleGetCountryDropdownRequest,
  handleBaseCurrencyDropdownRequest,
} = ComponentPropsManagement.actions;

export default ComponentPropsManagement.reducer;
