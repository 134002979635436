import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { Row, Col, Button, Card } from 'react-bootstrap'
import { BiSearchAlt2 } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';


function TiersEditModal(props) {
    const { program } = props;
    const [formData, setFormData] = useState(program);

    // Function to handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <Modal
            {...props}
            fullscreen={true}
            aria-labelledby="contained-modal-title-vcenter"
            style={{ paddingLeft: "15px" }}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title> <b className='mx-3' >New Program</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className='bg-light ' style={{ minHeight: '100vh' }}>
                    <div className='container p-4 '>

                    </div>
                    <Card className='p-3 container'>
                        <div>
                            <h4>   <span style={{ color: 'black' }}>
                                <IoIosArrowBack className='cursor-pointer' onClick={() => props.onHide()} />
                            </span> Add New User</h4>

                            <hr />
                        </div>
                        <Row>
                            <Col xs={12} sm={4}>
                                <Form>
                                    <Form.Label>Tier Code</Form.Label>
                                    <FormControl
                                        name="tier_code"
                                        value={formData.tier_code}
                                        onChange={handleChange}
                                        placeholder="Enter Tier Code"
                                    />
                                    <Form.Label className="mt-3">Qualification Period</Form.Label>
                                    <FormControl
                                        name="qualification_period"
                                        value={formData.qualification_period}
                                        onChange={handleChange}
                                        placeholder="Enter Qualification Period"
                                    />
                                </Form>
                            </Col>
                            <Col xs={12} sm={4}>
                                <Form>
                                    <Form.Label>Tier Name</Form.Label>
                                    <FormControl
                                        name="tier_name"
                                        value={formData.tier_name}
                                        onChange={handleChange}
                                        placeholder="Enter Tier Name"
                                    />
                                    <Form.Label className="mt-3">Tier Award Period</Form.Label>
                                    <FormControl
                                        name="tier_award_period"
                                        value={formData.tier_award_period}
                                        onChange={handleChange}
                                        placeholder="Enter Tier Award Period"
                                    />
                                </Form>
                            </Col>
                            <Col xs={12} sm={4}>
                                <Form>
                                    <Form.Label>Previous Tier Code</Form.Label>
                                    <FormControl
                                        name="previous_tier_code"
                                        value={formData.previous_tier_code}
                                        onChange={handleChange}
                                        placeholder="Enter Previous Tier Code"
                                    />
                                    <Form.Label className="mt-3">Next Tier Code</Form.Label>
                                    <FormControl
                                        name="next_tier_code"
                                        value={formData.next_tier_code}
                                        onChange={handleChange}
                                        placeholder="Enter Next Tier Code"
                                    />
                                </Form>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col xs={12} sm={10} >

                            </Col>
                            <Col>
                                <Button onClick={() => props.onHide()} variant="none" style={{ border: "solid lightgrey 1px" }}>Cancel</Button>
                                <Button className='ms-3'>Save</Button>
                            </Col>
                        </Row>

                    </Card>

                </div>



            </Modal.Body>
        </Modal>
    );
}

export default TiersEditModal;
