
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import DataService from "../services/requestApi";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  LabelList,
  Label,
} from 'recharts';

const chartConfig = {
  width: 550,
  height: 350,
  margin: { top: 15, right: 30, left: 20, bottom: 5 },
};

function PointActivity() {
  const [selectedYear, setSelectedYear] = useState('2023');
  const [selectedRedeemYear, setSelectedRedeemYear] = useState('2023');
  const [issued, setIssued] = useState([]);
  const [redeem, setRedeem] = useState([]);

  const GetAllIssued = async () => {
    try {
      const response = await DataService.GetIssuedAmount(selectedYear);
      console.log("API Response for Issued Amount", response);
      if (response.data.status) {
        setIssued(response.data.data);
      }
    } catch (error) {
      console.log('Error occurred while fetching issued data:', error);
    }
  }

  const GetAllRedeem = async () => {
    try {
      const response = await DataService.GetRedmptionAmount(selectedRedeemYear);
      console.log("API Response for Redemption Amount", response);
      if (response.data.status) {
        setRedeem(response.data.data);
      }
    } catch (error) {
      console.log('Error occurred while fetching redemption data:', error);
    }
  }

  useEffect(() => {
    if (selectedYear) {
      GetAllIssued();
    }
   
  }, [selectedYear, ]);
  useEffect(() => {
   
    if (selectedRedeemYear) {
      GetAllRedeem();
    }
  }, [ selectedRedeemYear]);

  const handleYearChange = (event, type) => {
    if (type === 'issued') {
      setSelectedYear(event.target.value);
    } else if (type === 'redeem') {
      setSelectedRedeemYear(event.target.value);
    }
  };

  const issuedChartData = issued.map(item => ({
    quantity: item.total_issue_amount,
    month: item.month,
  }));

  const redeemChartData = redeem.map(item => ({
    quantity: item.total_issue_amount,
    month: item.month,
  }));

  return (
    <>
      <Row className='p-2'>
        <Col className='container' xs={12} sm={6}>
          <div className='bg-white'
            style={{
              overflowX: "auto",
              boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
              borderRadius: "0.75rem",
              overflow: "hidden",
              fontFamily: "DM sans-serif",
              padding: "10px",
            }}
          >
            <div className='flex bg-white border-b mt-3 pb-2 '>
              <div className='bg-white pl-3 font-semibold d-flex justify-content-between'>
                <span style={{
                  color: 'var(--grey-700, #2F2F2F)',
                  fontFamily: 'Noto Sans',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}>
                  Points Issued
                </span>
                <select
                  className='text-center'
                  style={{
                    width: '171px',
                    height: '48px',
                    flexShrink: 0,
                    borderRadius: '4px',
                    background: 'var(--Primary-4, #F1F1FD)',
                  }}
                  value={selectedYear}
                  onChange={(e) => handleYearChange(e, 'issued')}
                >
                  <option value='2024'>2024</option>
                  <option value='2023'>2023</option>
                  <option value='2022'>2022</option>
                </select>
              </div>
            </div>
            <BarChart className='bg-white fw-bold ' {...chartConfig} data={issuedChartData}>
              <XAxis stroke="black" dataKey="month">
                <Label value="" offset={-5} position="insideBottom" className='font-bold text-xl text-black bg-white' />
              </XAxis>
              <YAxis stroke="black" label={{ value: 'Earned', angle: -90, position: 'insideLeft' }} className='font-bold text-xl text-black bg-white' />
              <ReferenceLine y={0} stroke="black" />
              <ReferenceLine y={500} stroke="black" />
              <ReferenceLine y={1000} stroke="black" />
              <ReferenceLine y={1500} stroke="black" />
              <Tooltip />
              <Bar dataKey="quantity" fill="#818cf8">
                <LabelList dataKey="quantity" position="insideTop" className='font-bold text-xl ' fill="white" />
              </Bar>
            </BarChart>
          </div>
        </Col>
        <Col className='container' xs={12} sm={6}>
          <div className='bg-white'
            style={{
              overflowX: "auto",
              boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
              borderRadius: "0.75rem",
              overflow: "hidden",
              fontFamily: "DM sans-serif",
              padding: "10px",
            }}
          >
            <div className='flex bg-white border-b mt-3 pb-2 '>
              <div className='bg-white pl-3 font-semibold d-flex justify-content-between'>
                <span style={{
                  color: 'var(--grey-700, #2F2F2F)',
                  fontFamily: 'Noto Sans',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}>
                  Points Redeemed
                </span>
                <select
                  className='text-center'
                  style={{
                    width: '171px',
                    height: '48px',
                    flexShrink: 0,
                    borderRadius: '4px',
                    background: 'var(--Primary-4, #F1F1FD)',
                  }}
                  value={selectedRedeemYear}
                  onChange={(e) => handleYearChange(e, 'redeem')}
                >
                  <option value='2024'>2024</option>
                  <option value='2023'>2023</option>
                  <option value='2022'>2022</option>
                </select>
              </div>
            </div>
            <BarChart className='bg-white fw-bold ' {...chartConfig} data={redeemChartData}>
              <XAxis stroke="black" dataKey="month">
                <Label value="" offset={-5} position="insideBottom" className='font-bold text-xl text-black bg-white' />
              </XAxis>
              <YAxis stroke="black" label={{ value: 'Redeemed', angle: -90, position: 'insideLeft' }} className='font-bold text-xl text-black bg-white' />
              <ReferenceLine y={0} stroke="black" />
              <ReferenceLine y={500} stroke="black" />
              <ReferenceLine y={1000} stroke="black" />
              <ReferenceLine y={1500} stroke="black" />
              <Tooltip />
              <Bar dataKey="quantity" fill="#ff7300">
                <LabelList dataKey="quantity" position="insideTop" className='font-bold text-xl ' fill="white" />
              </Bar>
            </BarChart>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default PointActivity;

