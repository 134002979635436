import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap';
function TransectionActivity() {
    const [selectedYear, setSelectedYear] = useState('2023');

    const handleYearChange = (event) => {
      setSelectedYear(event.target.value);
    };
  return (

    <div> 
        <Row>
   
 
  <Col className='mt-2' xs={12} sm={6} style={{height:"60vh"}}>
    <div className="bg-white  h-100 w-100 d-flex align-items-start justify-content-between" 
     style={{
      overflowX: "auto",
      boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
      borderRadius: "0.75rem",
      overflow: "hidden",
      fontFamily: "DM sans-serif",
      fontSize: "1.125rem",
      border: "0 2px 12px rgba(36, 36, 39, .12)",
      padding: "10px",
    }}>
      <Row className="d-flex">
        <Col  >
       
            <span>Points Earned</span>
          
         
        </Col>
        <Col >
        <select
className='text-center'
        style={{
          width: '171px',
          height: '48px',
          flexShrink: 0,
          borderRadius: '4px',
          background: 'var(--Primary-4, #F1F1FD)',
        }}
      
          value={selectedYear}
          onChange={handleYearChange}
        >
          <option value='2023'>2023</option>
          <option value='2022'>2022</option>
          <option value='2022'>2021</option>

          {/* Add more years as needed */}
        </select>
        </Col>
        <svg xmlns="http://www.w3.org/2000/svg" width="669" height="2" viewBox="0 0 669 2" fill="none">
<path d="M1 1L668 0.999942" stroke="#B0B0B0" stroke-linecap="round"/>
</svg>
      </Row>
    </div>
      <div style={{    position: "relative",
bottom: "200px",
left: "120px",
color: 'var(--grey-300, #B0B0B0)',
fontFamily: 'Noto Sans',
fontSize: '22px',
fontStyle: 'normal',
fontWeight: 400,
lineHeight: 'normal',}}>No data to show yet</div>



  </Col></Row> </div>
  )
}

export default TransectionActivity