import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Card } from 'react-bootstrap'
import { Link, Outlet } from 'react-router-dom'
import DataService from "../../Components/services/requestApi";
const Loyalty = () => {

    const [loyality, setloyality]= useState([])
    const { client_id } = JSON.parse(localStorage.getItem("User_data"));
    const GetAllLoyalityProgram=async()=>{
      try {
        const response =await DataService.GetLoyalityProgram(client_id)
        console.log("Get loylity issue", response.data)
        setloyality(response.data.data)
      } catch (error) {
        console.log("error", error)
      }
    
    }
    useEffect(() => {
        GetAllLoyalityProgram()
    }, [])
    

    const scrollableContainerStyle = {
        height: "80vh",  // Takes 80% of the viewport height
        maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
        overflowY: "auto",
        fontFamily: "Inter",
        padding: "16px", // Optional: Add some padding if needed
        boxSizing: "border-box", // Ensure padding is included in height calculation
      };

      
      // In case you are using flexbox for your layout
      const containerWrapperStyle = {
        display: "flex",
        flexDirection: "column",
        height: "100vh", // Full height of the viewport
      };
    return (
        <div style={containerWrapperStyle}>
        <div className='bg-light mb-5' style={scrollableContainerStyle}>
            <div >
                <div className='container p-4 '>
                    <Row>

                        <Col xs={12} sm={9}>
                            <h4>
                                <span >Loyalty Programs</span>
                            </h4>

                        </Col>
                        <Col>
                            <Button 
                            style={{  display: 'inline-flex',
                            padding: '11px 38px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '10px',
                            background: 'var(--Primary-1, #565ADD)',
                            boxShadow: '0px 3px 13px 0px rgba(57, 57, 57, 0.07)',}}>
                               <Link to={'/createnewprogram'} className='text-nowrap' style={{color:"white",textDecoration:"none"}} >Create New Program</Link>
                            </Button>
                        </Col>
                    </Row>
             
                
                    <div>
            {loyality.map(program => (
                <div key={program.code} className='bg-white mt-3'
                     style={{ overflowX: "auto",
                              boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                              borderRadius: "0.75rem",
                              overflow: "hidden",
                              fontFamily: "DM sans-serif",
                              padding: "20px"}}>
                    <div>
                        <h4>{program.program_Name}</h4>
                        <h6 style={{ marginTop: '-30px' }} className='float-end'>
                            <Link to={`/programdetails/${program.program_id}`} style={{ textDecoration: 'none', color: 'var(--Primary-1, #565ADD)', textAlign: 'right', fontFamily: 'Noto Sans', fontSize: '18px', fontStyle: 'normal', fontWeight: 500, lineHeight: 'normal' }}>View Details</Link>
                        </h6>
                        <hr />
                    </div>
                    <div>
                        <Row>
                            <Col md='4' sm='6'>
                                <h6 style={{ color: 'var(--Grey-900, #2C2C2C)', fontFamily: 'Noto Sans', fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: 'normal' }}>Code</h6>
                                <p style={{ color: 'var(--grey-500, #6B6B6B)', fontFamily: 'Noto Sans', fontSize: '18px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }}>{program.code}</p>
                            </Col>
                            <Col md='8' sm='12'>
                                <h6 style={{ color: 'var(--Grey-900, #2C2C2C)', fontFamily: 'Noto Sans', fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: 'normal' }}>Description</h6>
                                <p style={{ color: 'var(--grey-500, #6B6B6B)', fontFamily: 'Noto Sans', fontSize: '18px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }}>{program.description}</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            ))}
        </div>
                {/* <div className='mt-3'>
                <div className='bg-white mt-3'
                      style={{  overflowX: "auto",
                      boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                      borderRadius: "0.75rem",
                      overflow: "hidden",
                      fontFamily: "DM sans-serif",
                      padding: "20px",}}>
                        <div>
                            <h4> Platinum Program</h4>

                            <h6 style={{ marginTop: '-30px' }} className='float-end'><a href="edt" style={{ textDecoration: 'none',
                            color: 'var(--Primary-1, #565ADD)',
                            textAlign: 'right',
                            fontFamily: 'Noto Sans',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: 'normal', }}>View Details</a></h6>
                            <hr />
                        </div>
                        <div>
                            <Row>
                                <Col md='4' sm='6'>
                                    <h6 style={{ color: 'var(--Grey-900, #2C2C2C)',
  fontFamily: 'Noto Sans',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',}}>Code</h6>
                                    <p style={{  color: 'var(--grey-500, #6B6B6B)',
  fontFamily: 'Noto Sans',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',}}> 51862sd62</p>
                                </Col>
                                <Col md='8' sm='12' >
                                    <p style={{ color: 'var(--Grey-900, #2C2C2C)',
  fontFamily: 'Noto Sans',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',}}> Description</p>
                                    <p style={{  color: 'var(--grey-500, #6B6B6B)',
  fontFamily: 'Noto Sans',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',}}> Lorem ipsum dolor sit amet consectetur. Vel pulvinar dignissim tellus justo dolor. Mauris vitae purus pharetra ac ultricies. Enim molestie mattis fermentum sit facilisis sed aliquam
                                        mi. Lectus lacinia lacus hac eget pellentesque ac curabitur</p>
                                </Col>


                            </Row>
                        </div>
                      
                    </div>
                    <Outlet/>
                </div> */}
              
            </div >
         
            </div>
       
        </div>
        </div>
    )
}
export default Loyalty



