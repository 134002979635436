import http from "./http-common";

class DataService {
    // auth api's 
    login(data) {
        return http.post('/loyalty/auth/user-login', data);
    }
    enrollMember(formData) {
        return http.post('/loyalty/customer', formData);
    }
    createNewLoylityProgram(client_id, data) {
        return http.post(`/program/save-new-loyalty-program/${client_id}`, data);
      }
      GetLoyalityProgram(client_id) {
        return http.get(`/program/get-loyalty-program-by-clientId/${client_id}`);
      }
      GetLoyaltyProgramByCode(client_id,program_id) {
        return http.get(`/program/get-loyalty-program-by-clientId-and-programId/${client_id}/${program_id}`);
      }
      updateGeneralByCode(client_id,program_id,data ) {
        return http.put(`/program/update-general-loyalty-program/${client_id}/${program_id}`, data);
      }
      updatePolicesByCode(client_id,program_id,data ) {
        return http.put(`/program/update-policies-loyalty-program/${client_id}/${program_id}`, data);
      }
      updateTiersByCode(client_id,program_id,data ) {
        return http.put(`/program/update-tier-loyalty-program/${client_id}/${program_id}`, data);
      }
      deleteLoyalityProgramBycode(client_id,program_id) {
        return http.delete(`/program/delete-loyalty-program/${client_id}/${program_id}`);
      }
      GetAllTransection(client_id) {
        return http.get(`/loyalty/get-client-invoices/${client_id}`);
      }
      GetIssuedAmount(date) {
        return http.get(`/loyalty/get-issued-amount/${date}`);
      }
      GetRedmptionAmount(date) {
        return http.get(`/loyalty/get-redemption-amount/${date}`);
      }
      GetMonthlyData(year,month){
        return http.get(`/loyalty/get-all-customers-by-month/${year}/${month}`);
      }
}
export default new DataService();