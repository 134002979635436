import React, { useState, useEffect } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// import logo from "../../assets/images/favicon.png";
import logo from "../../assets/images/logo1.jpeg";
import { BiChevronDown } from "react-icons/bi";

import {
  handleLogout,
  handleGetUserData,
} from "../../redux/actions-reducers/ComponentProps/ComponentPropsManagement";

import { useDispatch, useSelector } from "react-redux";
import { FiLogOut } from "react-icons/fi";

const NavbarComponent = () => {
  const dispatch = useDispatch();
  const { login_data } = useSelector(e => e.ComponentPropsManagement)

  return (
    <>
      <div style={{ background: "#0073E8", padding: "5px" }}>
        <div className="d-flex justify-content-between px-5">
          <div className="d-flex">
            <div>
              <img src={logo} alt="logo" style={{ width: "100px" }} />
            </div>
            <div
              style={{
                color: "white",
                marginTop: "auto",
                marginBottom: "auto",
                cursor: "default",
                marginLeft: "8px",
                letterSpacing: "0.6px",
              }}
            >
              {/* <div
                style={{ fontWeight: "bold", position: "relative", top: "5px" }}
              >
                Photon
              </div>
              <div
                style={{ position: "relative", top: "-2px", fontSize: "12px" }}
              >
                <small>For a better tomorrow</small>
              </div> */}
            </div>
          </div>

          {login_data && (
            <>
              <div className="my-auto">
                <UncontrolledDropdown setActiveFromChild>
                  <DropdownToggle
                    // caret
                    style={{ color: "white" }}
                    className="nav-link"
                    tag="div"
                  >
                    <div className="d-flex" style={{ cursor: "pointer" }}>
                      <div className="me-1 my-auto">{login_data.username}</div>
                      {/* <div>
                    <img
                      src="https://media.istockphoto.com/id/1009749608/photo/young-woman-portrait-in-the-city.jpg?s=612x612&w=0&k=20&c=nBmdXrCkI6Zz-J1EkCGQAamaYlZeIOQAtKunC4WMT-U="
                      alt="no"
                      style={{
                        width: "40px",
                        borderRadius: "50%",
                        height: "40px",
                      }}
                    />
                  </div> */}
                      <div className="my-auto">
                        <BiChevronDown size={25} />
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      // active
                      onClick={() => {
                        dispatch(handleLogout());
                      }}
                      tag="span"
                      style={{ cursor: "pointer" }}
                    >
                      <FiLogOut style={{ position: "relative", top: "-1px" }} />
                      &nbsp;&nbsp;Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NavbarComponent;
