import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "reactstrap";
import { InputGroup, FormControl } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { BiChevronLeft } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { handleChaneSwitchTab, handleAllInvoicesDataRequest } from "../../../redux/actions-reducers/ComponentProps/ComponentPropsManagement"
import "./index.css";
import { useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { BACKEND_URL } from "../../utils/constant";

function Invoice() {
  const dispatch = useDispatch();
  const { loyaltyId } = useParams();
  const { switchTab } = useSelector((e) => e.ComponentPropsManagement);
  const itemArray = [
    {
      label: "Accrual",
      value: "Accrual",
      className: "",
      isActive: true,
    },
    {
      label: "Redemption",
      value: "Redemption",
      className: "",
      isActive: true,
    },
    {
      label: "All",
      value: "All",
      className: "",
      isActive: true,
    },
  ];

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/CustomerOverview");
  };

  const inlineStyles = {
    color: "var(--grey-700, #2F2F2F)",
    fontFamily: "Noto Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };

  const { all_invoices } = useSelector((e) => e.ComponentPropsManagement);

  const [allInvoices, setAllInvoices] = useState("");
  const getInvoices = () => {
    axios
      .get(
        `${BACKEND_URL}/loyalty/get-customer-invoices/${client_id}/${loyaltyId}`
      )
      .then((res) => {
        setAllInvoices(res.data.data);
      })
      .catch((err) => {
        console.log("ERR INVOICES", err);
      });
  };

  useEffect(() => {
    getInvoices();
    const allTab = itemArray.find((item) => item.value === "All");
    handleSwitch(allTab);
  }, []);

  const [radioFlag, setRadioFlag] = useState("accrual");
  const handleSwitch = (item) => {
    dispatch(handleChaneSwitchTab(item.value));
    setRadioFlag(item.value.toLowerCase());

    if (item.value === "Accrual") {
      axios
        .get(
          `${BACKEND_URL}/loyalty/get-client-invoices/${client_id}/${loyaltyId}/ISSUED`
        )
        .then((res) => {
          setAllInvoices(res.data.data);
        })
        .catch((err) => {
          console.log("ERR INVOICES", err);
        });
    } else if (item.value === "Redemption") {
      axios
        .get(
          `${BACKEND_URL}/loyalty/get-client-invoices/${client_id}/${loyaltyId}/REDEEMED`
        )
        .then((res) => {
          setAllInvoices(res.data.data);
        })
        .catch((err) => {
          console.log("ERR INVOICES", err);
        });
    }else if (item.value === "All") {
      getInvoices()
    }
    // No need to handle the "All" tab here, as it's handled in the useEffect
  };

  useEffect(() => {
    dispatch(
      handleAllInvoicesDataRequest({
      })
    );
  }, [allInvoices]);

  const [invoiceNumber, setInvoiceNumber] = useState("");
  const { client_id } = JSON.parse(localStorage.getItem("User_data"));

  const handleSubmit = (event) => {
    event.preventDefault();
    if (invoiceNumber.length >= 2) {
      axios
        .get(
          `${BACKEND_URL}/loyalty/get-by-invoiceNo-and-loyaltyId/${invoiceNumber}/${loyaltyId}`
        )
        .then((res) => {
          setAllInvoices([res.data.data]);
        })
        .catch((err) => {
          console.log("ERR CUSTOMER INVOICE NO.", err);
        });
    } else {
      setAllInvoices(allInvoices);
    }
  };








  const handleInputChange = (e) => {
    const value = e.target.value;
    setInvoiceNumber(value);
    // Trigger API request when input is cleared or becomes empty
    if (!value || value.length === 0) {
      getInvoices();
    }
  };



  const columns = [
    {
      name: "Invoice No.",
      center: true,
      minWidth: "150px",
      // wrap: true,
      selector: (row) => row.invoice_number,
      cell: (row) => {
        return (
          <>
            <span style={{ color: "var(--primary)", fontWeight: "bold" }}>
              {row.invoice_no}
            </span>
          </>
        );
      },
    },
    {
      name: "Status",
      center: true,
      minWidth: "120px",
      selector: (row) => row.status,
    },
    {
      name: "Type",
      center: true,
      minWidth: "120px",
      selector: (row) => row.type,
    },
    {
      name: "Member Id",
      center: true,
      minWidth: "200px",
      selector: (row) => row.loyalty_id,
    },
    {
      name: "Posted Date",
      center: true,
      minWidth: "150px",
      selector: (row) => row.business_date,
    },
    {
      name: "Invoice Date",
      center: true,
      minWidth: "150px",
      selector: (row) => row.business_date,
    },
    {
      name: "Invoice Qty",
      center: true,
      minWidth: "130px",
      selector: (row) => row.invoice_qty,
    },
    {
      name: "Location",
      center: true,
      minWidth: "120px",
      selector: (row) => row.store_id,
    },
    {
      name: "Eligible Amount (AED)",
      center: true,
      minWidth: "200px",
      selector: (row) => row.eligble_amount,
    },
    {
      name: "Net Amount",
      center: true,
      minWidth: "150px",
      selector: (row) => row.net_amount,
    },
    {
      name: "Points",
      center: true,
      minWidth: "120px",
      selector: (row) => row.issued_amount,
    },
  ];

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        // minHeight: '72px', // override the row height
        // border: "1px solid var(--secondary2) !important",
        borderBottom: "1px solid var(--secondary2) !important",
        marginBottom: "5px",
        // wordWrap: "break-word",
        whiteSpace: "unset !important",
      },
    },
    headCells: {
      style: {
        backgroundColor: "var(--secondary3)",
        fontWeight: "bold",
        fontSize: "14px",
        textTransform: "uppercase",
        marginBottom: "10px",
        // wordWrap: "break-word",
        padding: "15px 20px",
        whiteSpace: "unset !important",
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
      },
    },
    cells: {
      style: {
        whiteSpace: "unset !important",
        // wordWrap: "break-word",
        // paddingLeft: '8px', // override the cell padding for data cells
        // paddingRight: '8px',
        // border: "1px solid var(--secondary2)",
        // borderBottom: "1px solid var(--secondary2)"
      },
    },
    headRow: {
      style: {
        whiteSpace: "unset !important",
      },
    },
    header: {
      style: {
        whiteSpace: "unset !important",
      },
    },
  };

  return (
    <div className="bg-light" style={{ minHeight: '80vh' }}>
    <div className=' container' style={{ minHeight: 'fit-content' }}> 

      <div className='text-4xl font-bold  pl-[20px] flex  '>
      
        <div className='d-flex' >
        <BiChevronLeft size={35} className='mt-2' onClick={handleGoBack} />
        <h4 className='m-2'>
            <b className="m-3"  style={{inlineStyles}}>Invoices</b>
          </h4>
        </div>

      </div>

      <div className="container-style">
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {itemArray.map((item) => {
            return (
              <>
                <div
                  className={`item-style ${
                    item.value === switchTab ? "item-style-active" : ""
                  }`}
                  onClick={() => handleSwitch(item)}
                >
                  {item.label}
                </div>
              </>
            );
          })}
        </div>
      </div>

      <div className='container mt-4' 
      >
        <Row className='m-1'>
          <Col xs={12} sm={8}
          >
            <InputGroup className="mb-3" 
            >
              <FormControl
                placeholder="Search Invoice Number"
                aria-label="Search"
                aria-describedby="basic-addon2"
                value={invoiceNumber}
                onChange={handleInputChange}
              />
              <Button onClick={handleSubmit} style={{width: '77px',
  height: '40px',
  flexShrink: 0,
  background: '#565ADD',}}><FaSearch /> </Button>

            </InputGroup>
          </Col>
        </Row>

       


      </div>
 
      <div className=" bg-white   container mb-3  "
      style={{  overflowX: "auto",
      boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
      borderRadius: "0.75rem",
      overflow: "hidden",
      fontFamily: "DM sans-serif",
      padding: "10px",}}>
        <Row className=' m-1'>
        <div className="mt-3">
          <DataTable
            responsive={true}
            columns={columns}
            data={allInvoices}
            style={{
              whiteSpace: "unset !important",
            }}
            fixedHeader={true}
            fixedHeaderScrollHeight="600px"
            customStyles={customStyles}
          />
        </div>
    </Row>
        </div>


   
      </div>   
      

    </div>
  )
}

export default Invoice