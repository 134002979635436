// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-style {
    background: var(--primary);
    color: white;
    padding: 6px 25px;
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}

input:focus-visible {
    outline: none !important;
}

.customer-count {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Customer/Invoices/index.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".btn-style {\r\n    background: var(--primary);\r\n    color: white;\r\n    padding: 6px 25px;\r\n    border-radius: 30px;\r\n    text-transform: uppercase;\r\n    font-weight: 600;\r\n    cursor: pointer;\r\n}\r\n\r\ninput:focus-visible {\r\n    outline: none !important;\r\n}\r\n\r\n.customer-count {\r\n    margin-top: 30px;\r\n    margin-bottom: 20px;\r\n    font-size: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
