import { put, takeEvery, all ,call, takeLatest} from "redux-saga/effects";
import { toast } from "react-toastify";
import { handleAllCustomerDataResponse } from '../redux/actions-reducers/ComponentProps/ComponentPropsManagement';
import { BACKEND_URL } from "../Components/utils/constant";

function* handleLoginRequest(e) {
  // const { username, password } = e.payload;
  const response = yield fetch(`${BACKEND_URL}/loyalty/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(e.payload),
  });
  const jsonData = yield response.json();
  if (jsonData) {
    if (jsonData.username && jsonData.client_id) {
      toast.success("Login Successfully");
      localStorage.setItem("client_id", jsonData.client_id);
      localStorage.setItem("login_data", JSON.stringify(jsonData));
      yield put({
        type: "ComponentPropsManagement/handleLoginResponse",
        data: jsonData,
      });
     
      // location.replace("/");
     
    } else {
      toast.error("Please enter correct username and password");
      yield put({
        type: "ComponentPropsManagement/handleLoginResponse",
        data: [],
      });
    }
  }
}

function* handleAllCustomerRequest() {
  try {
    const { client_id } = JSON.parse(localStorage.getItem("User_data"));
    const response = yield fetch(
      `${BACKEND_URL}/loyalty/get-all-customers/${client_id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const jsonData = yield response.json();
    if (jsonData && jsonData.data && jsonData.data.length > 0) {
      yield put({
        type: "ComponentPropsManagement/handleAllCustomerDataResponse",
        data: jsonData.data,
      });
    } else {
      yield put({
        type: "ComponentPropsManagement/handleAllCustomerDataResponse",
        data: [],
      });
    }
  } catch (error) {
    // Handle error if needed
    console.error("Error in handleAllCustomerRequest saga:", error);
    yield put({
      type: "ComponentPropsManagement/handleAllCustomerDataResponse",
      data: [],
    });
  }
}

function* handleAllInvoicesRequest(e) {
  const { login_data } = e.payload;
  const response = yield fetch(
    `${BACKEND_URL}/loyalty/get-customer-invoices/8/81610237021438033INR`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const jsonData = yield response.json(e);
  if (jsonData) {
    if (jsonData.data && jsonData.data.length > 0) {
      yield put({
        type: "ComponentPropsManagement/handleAllInvoicesDataResponse",
        data: jsonData.data,
      });
      return;
    }
  }
  yield put({
    type: "ComponentPropsManagement/handleAllInvoicesDataResponse",
    data: [],
  });
}

function* handleAllPointActivityAPIRequest(e) {
  console.log("E", e);
  const { login_data } = e.payload;
  const response = yield fetch(
    `${BACKEND_URL}/loyalty/get-point-activity/${client_id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const jsonData = yield response.json(e);
  console.log("JSONDATA", jsonData);
  if (jsonData) {
    yield put({
      type: "ComponentPropsManagement/handleAllPointActivityAPIResponse",
      data: jsonData,
    });
  } else {
    yield put({
      type: "ComponentPropsManagement/handleAllPointActivityAPIResponse",
      data: "",
    });
  }
}
function* handleAllTransactiontActivityAPIRequest(e) {
  // console.log("E", e);
  const { login_data } = e.payload;
  // console.log("LOGIN DATA", client_id);
  const response = yield fetch(
    `${BACKEND_URL}/loyalty/get-point-activity/${client_id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const jsonData = yield response.json(e);
  // console.log("SAGA RESPONSE TRANSACTION", jsonData);
  if (jsonData) {
    yield put({
      type: "ComponentPropsManagement/handleAllTransactionActivityAPIResponse",
      data: jsonData,
    });
  } else {
    yield put({
      type: "ComponentPropsManagement/handleAllTransactionActivityAPIResponse",
      data: "",
    });
  }
}

function* handleGetLanguageRequest(e) {
  const response = yield fetch(
    `${BACKEND_URL}/loyalty/get-languages`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(e.payload),
    }
  );
  const jsonData = yield response.json();
  if (jsonData) {
    if (jsonData.language && jsonData.language.length > 0) {
      const t1 = [];
      jsonData.language.map((item) => {
        t1.push({ label: item, value: item });
      });
      yield put({
        type: "ComponentPropsManagement/handleGetLanguageResponse",
        data: t1,
      });
      return;
    }
    toast.error("Language not available");
    yield put({
      type: "ComponentPropsManagement/handleGetLanguageResponse",
      data: [],
    });
  }
}

function* handleGetNationalityRequest(e) {
  const response = yield fetch(
    `${BACKEND_URL}/loyalty/get-nationality`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(e.payload),
    }
  );
  const jsonData = yield response.json();
  if (jsonData) {
    if (jsonData.nationality && jsonData.nationality.length > 0) {
      const t1 = [];
      jsonData.nationality.map((item) => {
        t1.push({ label: item, value: item });
      });
      yield put({
        type: "ComponentPropsManagement/handleGetNationalityResponse",
        data: t1,
      });
      return;
    }
    toast.error("Nationality not available");
    yield put({
      type: "ComponentPropsManagement/handleGetNationalityResponse",
      data: [],
    });
  }
}

function* handleCityDetailDropdownRequest(e) {
  const { country } = e.payload;

  const response = yield fetch(
    `${BACKEND_URL}/loyalty/get-city-details/${country}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(e.payload),
    }
  );
  const jsonData = yield response.json();
  if (jsonData) {
    if (jsonData.length > 0) {
      const t1 = [];
      jsonData.map((item) => {
        t1.push({ ...item, label: item.city_name, value: item.city_code });
      });

      yield put({
        type: "ComponentPropsManagement/handleCityDetailDropdownResponse",
        data: t1,
      });
      return;
    }
    toast.error("City not available");
    yield put({
      type: "ComponentPropsManagement/handleCityDetailDropdownResponse",
      data: [],
    });
  }
}

function* handleLocationDetailDropdownRequest(e) {
  const { country } = e.payload;

  const response = yield fetch(
    `${BACKEND_URL}/loyalty/get-location-details/${country}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(e.payload),
    }
  );
  const jsonData = yield response.json();
  if (jsonData) {
    if (jsonData.length > 0) {
      const t1 = [];
      jsonData.map((item) => {
        t1.push({
          ...item,
          label: item.location_name,
          value: item.location_id,
        });
      });

      yield put({
        type: "ComponentPropsManagement/handleLocationDetailDropdownResponse",
        data: t1,
      });
      return;
    }
    toast.error("Location not available");
    yield put({
      type: "ComponentPropsManagement/handleLocationDetailDropdownResponse",
      data: [],
    });
  }
}

function* handleGetCountryDropdownRequest(e) {
  const response = yield fetch(
    `${BACKEND_URL}/loyalty/get-country-list`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(e.payload),
    }
  );
  const jsonData = yield response.json();
  if (jsonData) {
    if (jsonData.data && jsonData.data.length > 0) {
      const t1 = [];
      jsonData.data.map((item) => {
        t1.push({ ...item, label: item.countryName, value: item.countryCode });
      });

      yield put({
        type: "ComponentPropsManagement/handleGetCountryDropdownResponse",
        data: t1,
      });
      return;
    }
    toast.error("Country not available");
    yield put({
      type: "ComponentPropsManagement/handleGetCountryDropdownResponse",
      data: [],
    });
  }
}

function* handleBaseCurrencyDropdownRequest(e) {
  const { country } = e.payload;
  const response = yield fetch(
    `${BACKEND_URL}/loyalty/get-currency/${country}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(e.payload),
    }
  );
  const jsonData = yield response.json();
  if (jsonData) {
    if (jsonData.data && jsonData.data.length > 0) {
      const t1 = [];
      jsonData.data.map((item) => {
        t1.push({
          ...item,
          label: item.currencyName,
          value: item.currencyCode,
        });
      });

      yield put({
        type: "ComponentPropsManagement/handleBaseCurrencyDropdownResponse",
        data: t1,
      });
      return;
    }
    toast.error("Currency not available");
    yield put({
      type: "ComponentPropsManagement/handleBaseCurrencyDropdownResponse",
      data: [],
    });
  }
}

export function* helloSaga() {
  yield takeEvery(
    "ComponentPropsManagement/handleLoginRequest",
    handleLoginRequest
  );
 
  yield takeLatest("ComponentPropsManagement/fetchAllCustomers", handleAllCustomerRequest);
  yield takeEvery(
    "ComponentPropsManagement/handleGetLanguageRequest",
    handleGetLanguageRequest
  );
  yield takeEvery(
    "ComponentPropsManagement/handleAllTransactionActivityAPIDataRequest",
    handleAllTransactiontActivityAPIRequest
  );
  yield takeEvery(
    "ComponentPropsManagement/handleGetNationalityRequest",
    handleGetNationalityRequest
  );
  yield takeEvery(
    "ComponentPropsManagement/handleCityDetailDropdownRequest",
    handleCityDetailDropdownRequest
  );
  yield takeEvery(
    "ComponentPropsManagement/handleLocationDetailDropdownRequest",
    handleLocationDetailDropdownRequest
  );
  yield takeEvery(
    "ComponentPropsManagement/handleGetCountryDropdownRequest",
    handleGetCountryDropdownRequest
  );
  yield takeEvery(
    "ComponentPropsManagement/handleBaseCurrencyDropdownRequest",
    handleBaseCurrencyDropdownRequest
  );
  yield takeEvery(
    "ComponentPropsManagement/handleAllPointActivityAPIDataRequest",
    handleAllPointActivityAPIRequest
  );
}

// export function* incrementAsync() {
//     yield delay(1000)
//     yield put({ type: 'INCREMENT' })
// }

// export function* watchIncrementAsync() {
//     yield takeEvery('INCREMENT_ASYNC', incrementAsync)
// }

// export function* saga() {

// }

export default function* rootSaga() {
  yield all([
    helloSaga(),
    // watchIncrementAsync()
  ]);
}

// export default saga
