import React, { useState, useEffect } from "react";
import "./App.css";
import NavbarComponent from "./Components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import SubNavbar from "./Components/SubNavbar";
import CustomerComponent from "./Components/Customer";
import Profile from "./Components/Profile";
import InvoiceCustomer from "./Components/Customer/Invoices";
import CustomerOverview from "./Components/Customer/Overview";
import MemberEnrollment from "./Components/Customer/memberEnrolment";
import Transactions from "./Components/Transactions/Transactions";
import InsightsComponent from "./Components/Insights";
import Login from "./Components/Login/Login";
import ProtectedRoutingWhenLogin from "./ProtectedRoutingWhenLogin";
import ProtectedRoutingWhenLogout from "./ProtectedRoutingWhenLogout";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import ProgramDetails from "./Components/Loyalty Program/ProgramDetails";
import Loyalty from "./Components/Loyalty Program/Loyalty_";
import General from "./Components/Loyalty Program/General";
import Newuser from "./Components/Loyalty Program/Newuser";
import {
  handleGetUserData,
  handleGetLanguageRequest,
  handleGetNationalityRequest,
  handleGetCountryDropdownRequest
} from "./redux/actions-reducers/ComponentProps/ComponentPropsManagement";
import Policies from "./Components/Loyalty Program/Policies";
import Tiers from "./Components/Loyalty Program/Tiers";
import Createnewprogram from "./Components/Loyalty Program/Createnewprogram";


function App() {

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      <Navigate to="/login" />
    }
  }, [token]);

 

  return (
    <>
      <BrowserRouter>

       <SubNavbar/>
        <Routes>
         
            <Route path="/" element={ <ProtectedRoutingWhenLogin Component={Home}/>}/>
            <Route path="/InsightsComponent-app" element={ <ProtectedRoutingWhenLogin Component={InsightsComponent}/>}/>
            <Route path="/CustomerComponent-app" element={ <ProtectedRoutingWhenLogin Component={CustomerComponent}/>}/>
            <Route path="/CustomerOverview" element={ <ProtectedRoutingWhenLogin Component={CustomerOverview}/>}/>
            <Route path="/MemberEnrollment" element={ <ProtectedRoutingWhenLogin Component={MemberEnrollment}/>}/>
            <Route path="/InvoiceCustomer/:loyaltyId" element={ <ProtectedRoutingWhenLogin Component={InvoiceCustomer}/>}/>
            <Route path="/transactions" element={ <ProtectedRoutingWhenLogin Component={Transactions}/>}/>
            <Route path="/Profile" element={ <ProtectedRoutingWhenLogin Component={Profile}/>}/>
            <Route path="/loyalty" element={ <ProtectedRoutingWhenLogin Component={Loyalty}/>}/>

             
            <Route path="/createnewprogram" element={<Createnewprogram/>}>
           
            </Route>
            <Route path="/newuser" element={<Newuser/>}/>
            <Route path="/programdetails/:program_id" element={<ProgramDetails />} />

                     {/* <Route path="/programdetails" element={<General/>}/> */}
                     <Route path="/programdetails/general" element={ <ProtectedRoutingWhenLogin Component={General}/>}/>
                     <Route path="/programdetails/policies" element={<Policies/>}/>
                     <Route path="/programdetails/tiers" element={<Tiers/>}/>
                  

           
           
   

        <Route
          path="/login"
          element={<ProtectedRoutingWhenLogout Component={Login} />}
        />


      </Routes>
    </BrowserRouter >
    </>
  );
}

export default App;
