import React, { useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { Form, FormControl } from 'react-bootstrap';
import { Link, Outlet, useParams, useNavigate } from 'react-router-dom'; // Import useNavigate hook
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import General from './General';
import Policies from './Policies';
import Tiers from './Tiers';
import DataService from "../../Components/services/requestApi";
import Swal from 'sweetalert2';

const ProgramDetails = () => {
    const navigate = useNavigate(); // Call useNavigate hook directly inside the functional component

    const { program_id } = useParams();

    const [program, setProgram] = useState(null);
    const { client_id } = JSON.parse(localStorage.getItem("User_data"));
    useEffect(() => {
        const fetchProgramDetails = async () => {
            try {
                const response = await DataService.GetLoyaltyProgramByCode(client_id, program_id);
                setProgram(response.data.data);
             
                console.log("first", response.data.data)
            } catch (error) {
                console.error('Error fetching program details:', error);
            }
        };

        fetchProgramDetails();
    }, [client_id, program_id]);

    if (!program) {
        return <div>Loading...</div>;
    }

    const deleteLoyaltyProgram = async () => {
        try {
            const response = await DataService.deleteLoyalityProgramBycode(client_id, program_id);
            console.log("Delete Concept", response.data);

            Swal.fire({
                title: 'Delete Loyalty Program Successfully',
                icon: "success",
                timer: 2000
            })
            navigate('/loyalty');
        } catch (error) {
            console.error("Error deleting concept", error);
        }
    };

    return (
        <div className='bg-light' style={{ minHeight: '100vh' }}>
            <div className="w-screen h-100">
                <div className='container'>
                    <Row>
                        <Col xs={12} sm={10}>
                            <div className="p-3">
                                <h4>
                                    <Link to="/loyalty" style={{ color: 'black' }}>
                                        <IoIosArrowBack />
                                    </Link>
                                    <b> Program Details</b>
                                </h4>
                            </div>
                        </Col>
                        <Col className='mt-3'>
                            <Button onClick={deleteLoyaltyProgram}>
                                <b>Delete Program</b>
                            </Button>
                        </Col>
                    </Row>

                    <Tabs
                        defaultActiveKey="Programdetails"
                        id="uncontrolled-tab-example"
                        className="mb-3  fw-bold"
                        fill
                        style={{
                            background: "#FFF",
                            boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
                        }}

                    >
                        <Tab eventKey="Programdetails" title="Programdetails">
                            <General program_id={program_id} program={program} />
                        </Tab>
                        <Tab eventKey="Policies" title="Policies">
                            <Policies program_id={program_id} program={program} />
                        </Tab>
                        <Tab eventKey="Tiers" title="Tiers">
                            <Tiers setProgram={setProgram} program_id={program_id} program={program} />
                        </Tab>
                    </Tabs>


                </div>
            </div>
        </div>
    );
};

export default ProgramDetails;
