// import { color } from 'd3';
import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Table } from "reactstrap";
import { InputGroup, FormControl } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DataTable from "react-data-table-component";
import { BACKEND_URL } from '../utils/constant';
function Customers() {
  const [users, setUsers] = useState([]);
  const [mobileValue, setMobileValue] = useState("");
  const navigate = useNavigate()
  function goToCustomer(row){
    navigate("/CustomerOverview", { state: { userData: row } });
    
  }
  function goToMemberEnrollment(){
    navigate("/MemberEnrollment")
    
  }
  
  const {client_id } = JSON.parse(localStorage.getItem("User_data"));
  const handleSubmit = (event) => {
    event.preventDefault();
    // setRecords(users.filter((f) => f.mobile_number.includes(e.target.value)));
    if (mobileValue) {
      

      axios
        .post(
          `${BACKEND_URL}/loyalty/customer-details`,
          {
            base_currency: users.find(user => user.mobile_number === mobileValue)?.base_currency,
            mobile_number: mobileValue,
           
            client_id: client_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("SEARCHED VALUE", res.data);
          setUsers([res.data]);
        })
        .catch((err) => {
          console.log("SEARCHED VALUE", err);
        });
    } else {
      setUsers(users);
    }
  };

  const getAllUsers = () => {
    axios
      .get(`${BACKEND_URL}/loyalty/get-all-customers/${client_id}`)
      .then((res) => {
        const { data } = res.data;
        console.log("CUSTOMERS ALL DATA", data);
        setUsers(data);
        // setUsers(data);
      })
      .catch((err) => {
        console.log("ERROR ALL CUSTOMERS", err);
      });
  };


  useEffect(() => {
    getAllUsers();
  
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setMobileValue(value);

    // Trigger API request when input is cleared or becomes empty
    if (!value || value.length === 5) {
      getAllUsers();
    }
  };

  const inlineStyles = {
    color: "var(--grey-700, #2F2F2F)",
    fontFamily: "Noto Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };



  const columns = [
    {
      name: "CARD NO.",
      center: true,
      selector: (row) => row.loyalty_id,
      cell: (row) => {
        return (
          <>
            <span
              style={{
                color: "var(--primary)",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => goToCustomer(row)}
             
              
            >
              {row.loyalty_id}
            </span>
          </>
        );
      },
    },
    {
      name: "CUSTOMER NAME",
      center: true,
      selector: (row) => row.customer_name,
    },
    {
      name: "CONTACT NO.",
      center: true,
      selector: (row) => row.mobile_number,
    },
    {
      name: "EMAIL ID",
      center: true,
      selector: (row) => row.email_id,
    },
    // {
    //   //   name: <AiOutlineRight />,
    //   selector: (row) => row && <FiChevronRight size={23} />,
    // },
  ];


  const customStyles = {
    rows: {
      style: {
        // minHeight: '72px', // override the row height
        // border: "1px solid var(--secondary2) !important",
        border: "1px solid var(--secondary2) !important",
        marginBottom: "5px",
        borderRadius: "5px",
      },
    },
    headCells: {
      style: {
        backgroundColor: "var(--secondary3)",
        fontWeight: "bold",
        fontSize: "14px",
        textTransform: "uppercase",
        marginBottom: "10px",
        padding: "15px 20px",
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
      },
    },
    cells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for data cells
        // paddingRight: '8px',
        // border: "1px solid var(--secondary2)",
        // borderBottom: "1px solid var(--secondary2)"
      },
    },
  };

  const scrollableContainerStyle = {
    height: "80vh",  // Takes 80% of the viewport height
    maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px", // Optional: Add some padding if needed
    boxSizing: "border-box", // Ensure padding is included in height calculation
  };
  const TableContainerscrolStyle = {
    height: "50vh",  // Takes 80% of the viewport height
    maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px", // Optional: Add some padding if needed
    boxSizing: "border-box", // Ensure padding is included in height calculation
  };
  
  // In case you are using flexbox for your layout
  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full height of the viewport
  };
  return (
    <>
    <div style={containerWrapperStyle}>
        <div className='bg-light' style={scrollableContainerStyle} > 
        <div className='container'>
        <div className="p-2 ">
          <h4>
            <span  style={{inlineStyles}}>Customers</span>
          </h4>
        </div>
      </div >
     <div className='container'>
        <Row className='m-1'>
          <Col xs={12} sm={9}>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Search Customer by Mobile"
                aria-label="Search"
                aria-describedby="basic-addon2"
                 required
                      value={mobileValue}
                      onChange={handleInputChange}
              />
              <Button onClick={handleSubmit}  style={{width: '77px',
  height: '40px',
  flexShrink: 0,
  background: '#565ADD',}}><FaSearch /> </Button>

            </InputGroup>
          </Col>
          <Col xs={12} sm={3}>
            <Button className='text-nowrap' style={{  borderRadius: '10px',
  background: 'var(--Primary-1, #565ADD)',
  boxShadow: '0px 3px 13px 0px rgba(57, 57, 57, 0.07)',
  display: 'inline-flex',
  padding: '9px 37px 9px 38px',
  justifyContent: 'center',
  alignItems: 'center',}} onClick={()=>goToMemberEnrollment()}>Member Enrollment</Button>
          </Col>
        </Row>

       


      </div>
      <div className=" bg-white   container mb-3  "
      style={{  overflowX: "auto",
      boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
      borderRadius: "0.75rem",
      overflow: "hidden",
      fontFamily: "DM sans-serif",
      padding: "10px",}}>
        <Row className=' m-1' style={TableContainerscrolStyle}>
        <div className="mt-3">
            <DataTable
              columns={columns}
              data={users}
              customStyles={customStyles}
            />
          </div>
    </Row>
        </div>

        </div>
        </div>
    </>
  )
}

export default Customers