import React, { useState } from "react";
import { Row, Col, FormGroup, Input, Button, Form } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import loyalty from "../../assets/images/Frame 2.png";
import DataService from "../../Components/services/requestApi"; // Adjust the path accordingly
import axios from 'axios';
import Swal from "sweetalert2";

const Login = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({ user_name: '', password: '' });
  const { user_name, password } = userDetails;

  const changeHandler = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

 const subHandler = async (e) => {
  e.preventDefault();
  try {
    const params = {
      user_name,
      password,
    };
    const response = await DataService.login(params);

    if (response.data.status) {
      localStorage.setItem("token", response.data.data.jwt_token);
      localStorage.setItem("User_data", JSON.stringify(response.data.data.user));
      localStorage.setItem("Store_data", JSON.stringify(response.data.data.user_settings));

      navigate('/');
      Swal.fire({
        icon: 'success',
        title: 'Login Successfully',
        text: 'Welcome!',
      });
    } else {
      throw new Error(response.data.message || 'Unknown error occurred');
    }
  } catch (error) {
    Swal.fire('Oops!', error.message || 'An error occurred while processing your request', 'error');
    console.error('Error submitting form:', error);
  }
};

  

  return (
    <div className="bg-light d-flex" style={{ minHeight: "80vh" }}>
      <div className="container mt-5 ">
        <div
          className=" bg-white   loyality-login mb-3  "
          style={{
            overflowX: "auto",
            boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
            borderRadius: "0.75rem",
            overflow: "hidden",
            fontFamily: "DM sans-serif",
            padding: "20px",
          }}
        >
          <div className="loyality-login_container">
            <div
              className="my-4"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={loyalty}
                alt=""
                style={{ height: "100%", width: "25%" }}
              />
            </div>
            <Form onSubmit={(e) => subHandler(e)}>
              <Row>
                <Col>
                  <FormGroup>
                    <Input
                      type="text"
                      onChange={(e) => changeHandler(e)}
                      value={user_name}
                      name="user_name"
                      required={true}
                      placeholder="Username"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Input
                      type="password"
                      onChange={(e) => changeHandler(e)}
                      value={password}
                      required={true}
                      name="password"
                      placeholder="Password"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button
                color="primary"
                className="login_button"
                type="submit"
                style={{
                  borderRadius: "10px",
                  background: "var(--Primary-1, #565ADD)",
                  boxShadow: "0px 3px 13px 0px rgba(57, 57, 57, 0.07)",
                }}
              >
                Login
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
