import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { handleAllCustomerDataResponse } from "../../redux/actions-reducers/ComponentProps/ComponentPropsManagement";
import { Bar } from "react-chartjs-2";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import DataService from "../../Components/services/requestApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSimCard, faFileArchive } from "@fortawesome/free-solid-svg-icons";
import chip from "../../assets/images/chip.png";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Home = () => {
  const dispatch = useDispatch();
  const customerData = useSelector(
    (state) => state.ComponentPropsManagement.all_customers
  );
  const [Monthlydata, setMonthlydata] = useState([]);
  useEffect(() => {
    // Dispatch an action to initiate fetching customer data
    dispatch({ type: "ComponentPropsManagement/fetchAllCustomers" });
  }, [dispatch]);
  console.log("Customer Data:", customerData);

  const [selectedMonth, setSelectedMonth] = useState(["January"]);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [filteredData, setFilteredData] = useState(customerData);
  const [fildData, setFildData] = useState("");

  const handlecustomerdata = () => {
    setFildData(customerData);
  };
  useEffect(() => {
    if (customerData) {
      handlecustomerdata(fildData);
    }
    handleCheckboxChange(selectedCheckbox);
  }, [selectedCheckbox, customerData]);

  const handleMonthSelect = (month) => {
    setSelectedMonth([month]);
    setIsSelected(month);
  };
  
  const handleyearSelect = (e) => {
    setselectedYear(e.target.value);
    
  };

  const handleCheckboxChange = (checkboxValue) => {
    setSelectedCheckbox(checkboxValue);

    let filtered;
    if (!checkboxValue) {
      // Empty checkbox
      filtered = customerData;
    } else if (checkboxValue === "checkbox1") {
      // Earned
      filtered = customerData.filter(
        (customer) => customer.issued_amount !== undefined
      );
    } else if (checkboxValue === "checkbox2") {
      // Redeemed
      filtered = customerData.filter(
        (customer) => customer.redemption_amount !== undefined
      );
    } else if (checkboxValue === "checkbox3") {
      // Cancelled
      filtered = customerData.filter(
        (customer) => customer.cancelled_amount !== undefined
      );
    } else if (checkboxValue === "all") {
      // Show all
      filtered = customerData;
    }

    setFilteredData(filtered);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthData = {};

  // Initialize monthData with zeros for each month
  months.forEach((month) => {
    monthData[month] = { redemption: 0, issued: 0 };
  });

  // Calculate redemption and issued amounts for each month
  filteredData.forEach((customer) => {
    if (
      customer &&
      customer.modified_date &&
      customer.redemption_amount !== undefined &&
      customer.issued_amount !== undefined
    ) {
      const modifiedDate = new Date(customer.modified_date);
      if (!isNaN(modifiedDate)) {
        const month = modifiedDate.getMonth();
        if (months[month] && monthData[months[month]]) {
          monthData[months[month]].redemption += customer.redemption_amount;
          monthData[months[month]].issued += customer.issued_amount;
        }
      }
    }
  });

  // Calculate redemption and issued amounts for each month
  const getdata = months.map((month) => monthData[month].redemption);
  console.log("this month daata:", getdata);
  const data = {
    labels: selectedMonth,
    datasets: [
      {
        label: "Redemption Amount",
        // data: months.map(month => monthData[month].redemption),
        data: Monthlydata?.map((month) => month?.point_redeem),
        // data: 3000,
        backgroundColor: "#FF6384",
        hidden: selectedCheckbox === "checkbox1",
      },
      {
        label: "Issued Amount",
        // data: months.map(month => monthData[month].issued),
        data: Monthlydata?.map((month) => month?.point_earn),
        backgroundColor: "rgb(209, 211, 255)",
        hidden: selectedCheckbox === "checkbox2",
      },
    ],
  };

  const [isSelected, setIsSelected] = useState("January");
  const [selectedyear, setselectedYear] = useState(new Date().getFullYear());

  const GetDataByMonth = async (selectedyear,month) => {
    try {
      const response = await DataService.GetMonthlyData(selectedyear, month);
      setMonthlydata([response.data.data]);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };
  useEffect(() => {
    if(isSelected && selectedyear) {
      GetDataByMonth( selectedyear, isSelected);
    } 
  }, [isSelected, selectedyear]);
  
  const options = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "Redemption and Issued Amounts by Month" },
    },
  };

  const totalIssuedPoints = customerData.reduce((total, customer) => {
    return total + (customer.issued_amount || 0);
  }, 0);

  // Calculate total redemption points
  const totalRedemptionPoints = customerData.reduce((total, customer) => {
    return total + (customer.redemption_amount || 0);
  }, 0);

  const inlineStyles = {
    color: "var(--grey-700, #2F2F2F)",
    fontFamily: "Noto Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };

  const columns = [
    {
      name: "CARD NO.",
      center: true,
      selector: (row) => row.loyalty_id,
      cell: (row) => {
        return (
          <>
            <span
              style={{
                color: "var(--primary)",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              {row.loyalty_id}
            </span>
          </>
        );
      },
    },
    {
      name: "CUSTOMER NAME",
      center: true,
      selector: (row) => row.customer_name,
    },
    {
      name: "CONTACT NO.",
      center: true,
      selector: (row) => row.mobile_number,
    },
    {
      name: "EMAIL ID",
      center: true,
      selector: (row) => row.email_id,
    },
    // {
    //   //   name: <AiOutlineRight />,
    //   selector: (row) => row && <FiChevronRight size={23} />,
    // },
  ];

  const customStyles = {
    rows: {
      style: {
        // minHeight: '72px', // override the row height
        // border: "1px solid var(--secondary2) !important",
        border: "1px solid var(--secondary2) !important",
        marginBottom: "5px",
        borderRadius: "5px",
      },
    },
    headCells: {
      style: {
        backgroundColor: "var(--secondary3)",
        fontWeight: "bold",
        fontSize: "14px",
        textTransform: "uppercase",
        marginBottom: "10px",
        padding: "15px 20px",
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
      },
    },
    cells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for data cells
        // paddingRight: '8px',
        // border: "1px solid var(--secondary2)",
        // borderBottom: "1px solid var(--secondary2)"
      },
    },
  };

  const [loyality, setloyality] = useState([]);
  const userData = JSON.parse(localStorage.getItem("User_data"));
  const { client_id } = userData || {};
  const GetAllLoyalityProgram = async () => {
    try {
      const response = await DataService.GetLoyalityProgram(client_id);
      console.log("Get loylity issue", response.data);
      setloyality(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    GetAllLoyalityProgram();
  }, []);

  const scrollableContainerStyle = {
    height: "80vh", // Takes 80% of the viewport height
    maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px", // Optional: Add some padding if needed
    boxSizing: "border-box", // Ensure padding is included in height calculation
  };

  // In case you are using flexbox for your layout
  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full height of the viewport
  };
  return (
    <div style={containerWrapperStyle}>
      <div
        className="bg-light  scrollable-container p-3"
        style={scrollableContainerStyle}
      >
        <div className="container">
          <div className="p-3 ">
            <h4>
              <span>Welcome User</span>
            </h4>
          </div>
          <div>
            <Row>
              <Col xs={12} sm={7}>
                <Row>
                  <div
                    className="bg-white h-100 w-100"
                    style={{
                      overflowX: "auto",
                      boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                      borderRadius: "0.75rem",
                      overflow: "hidden",
                      fontFamily: "DM sans-serif",
                      // fontSize: "1.125rem",
                      border: "0 2px 12px rgba(36, 36, 39, .12)",
                      padding: "10px",
                    }}
                  >
                    <Row>
                      <Col className="w-25">
                        <p className="text-muted">Points Earned</p>
                        <h5 className="m-3">
                          {" "}
                          {Monthlydata?.map((month) => month?.point_earn)}
                        </h5>
                      </Col>

                      <Col className="w-25">
                        <p className="text-muted">Points Redeemed</p>
                        <h5 className="m-3">
                          {" "}
                          {Monthlydata?.map((month) => month?.point_redeem)}
                        </h5>
                      </Col>
                      <Col className="d-flex">
                      <input style={{
                              overflowX: "auto",
                              boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                              borderRadius: "0.75rem",
                              overflow: "hidden",
                              fontFamily: "DM sans-serif",
                              fontSize: "1.125rem",
                              padding: "10px",
                              width: "171px",
                              height: "48px",
                              flexShrink: "0",
                              background: "var(--Primary-4, #F1F1FD)",
                              color: "var(--grey-700, #2F2F2F)",
                            }} type="number" min="1900" max="2100" placeholder="Year" 
                            value={selectedyear}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (value > 2100) {
                                value = 2100;
                              } else if (value < 1900) {
                                value = 1900;
                              }
                              setselectedYear(value);
                            }}
                            />

                        {/* <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            style={{
                              overflowX: "auto",
                              boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                              borderRadius: "0.75rem",
                              overflow: "hidden",
                              fontFamily: "DM sans-serif",
                              fontSize: "1.125rem",
                              padding: "10px",
                              width: "171px",
                              height: "48px",
                              flexShrink: "0",
                              background: "var(--Primary-4, #F1F1FD)",
                              color: "var(--grey-700, #2F2F2F)",
                            }}
                          >
                            {selectedMonth}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {months.map((month) => (
                              <Dropdown.Item
                                key={month}
                                onClick={() => handleMonthSelect(month)}
                              >
                                {month}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown> */}
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            style={{
                              overflowX: "auto",
                              boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                              borderRadius: "0.75rem",
                              overflow: "hidden",
                              fontFamily: "DM sans-serif",
                              fontSize: "1.125rem",
                              padding: "10px",
                              width: "171px",
                              height: "48px",
                              flexShrink: "0",
                              background: "var(--Primary-4, #F1F1FD)",
                              color: "var(--grey-700, #2F2F2F)",
                            }}
                          >
                            {selectedMonth}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {months.map((month) => (
                              <Dropdown.Item
                                key={month}
                                onClick={() => handleMonthSelect(month)}
                              >
                                {month}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="669"
                        height="2"
                        viewBox="0 0 669 2"
                        fill="none"
                      >
                        <path
                          d="M1 1L668 0.999942"
                          stroke="#B0B0B0"
                          stroke-linecap="round"
                        />
                      </svg>
                      <Row>
                        <Bar options={options} data={data} />
                      </Row>
                    </Row>

                    {/* <div className="d-flex justify-content-between p-3" >
      <label >
        <input
         style={{
          color: 'var(--grey-700, #2F2F2F)',
          fontFamily: 'Noto Sans',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
        }}
          type="checkbox"
          value="checkbox1"
          checked={selectedCheckbox === "checkbox1"}
          onChange={() => handleCheckboxChange("checkbox1")}
        />
     <span className="m-1 fw-bold">Earned</span>
      </label>

      <label >
        <input
          style={{
          color: 'var(--grey-700, #2F2F2F)',
          fontFamily: 'Noto Sans',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
        }}
          type="checkbox"
          value="checkbox2"
          checked={selectedCheckbox === "checkbox2"}
          onChange={() => handleCheckboxChange("checkbox2")}
        />
        <span className="m-1 fw-bold">Redeemed</span>
      </label>

      <label >
        <input
         style={{
          color: 'var(--grey-700, #2F2F2F)',
          fontFamily: 'Noto Sans',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
        }}
          type="checkbox"
          value="checkbox3"
          checked={selectedCheckbox === "checkbox3"}
          onChange={() => handleCheckboxChange("checkbox3")}
        />
        <span className="m-1 fw-bold"  >Cancelled</span>
      </label>
      <label  style={{
      color: 'var(--Primary-1, #565ADD)',
      textAlign: 'right',
      fontFamily: 'Noto Sans',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    }}>
      View Detailed Insights
      </label>
    </div> */}
                  </div>
                </Row>

                <Row>
                  <div
                    className="bg-white overflow-scroll mt-2"
                    style={{
                      overflowX: "auto",
                      boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                      borderRadius: "0.75rem",
                      overflow: "hidden",
                      fontFamily: "DM sans-serif",
                      fontSize: "1.125rem",
                      border: "0 2px 12px rgba(36, 36, 39, .12)",
                      padding: "10px",
                      height: "50vh",
                    }}
                  >
                    <Row>
                      <Col xs={12} sm={10} className="">
                        <h6>Customers</h6>
                      </Col>
                      <Col className="">
                        <Link
                          to="CustomerComponent-app"
                          className="cursor-pointer"
                        >
                          View All
                        </Link>
                      </Col>
                    </Row>
                    <div className="mt-3">
                      <DataTable
                        columns={columns}
                        data={customerData}
                        customStyles={customStyles}
                      />
                    </div>
                  </div>
                </Row>
              </Col>
              <Col>
                <div
                  className="bg-white h-100  d-flex align-items-start justify-content-between"
                  style={{
                    overflowX: "auto",
                    boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                    borderRadius: "0.75rem",
                    overflow: "hidden",
                    fontFamily: "DM sans-serif",
                    fontSize: "1.125rem",
                    border: "0 2px 12px rgba(36, 36, 39, .12)",
                    padding: "10px",
                  }}
                >
                  <Row>
                    <Col>
                      <h5>Loyalty Program</h5>
                      <span className="text-nowrap form-control-sm">
                        Send campaigns for all sorts of special occasions.
                      </span>
                    </Col>
                    <Col>
                      <Link to={"/createnewprogram"}>Create New</Link>
                    </Col>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="669"
                      height="2"
                      viewBox="0 0 669 2"
                      fill="none"
                    >
                      <path
                        d="M1 1L668 0.999942"
                        stroke="#B0B0B0"
                        stroke-linecap="round"
                      />
                    </svg>
                    <Row>
                      <div>
                        {loyality.map((program) => (
                          <div
                            key={program.code}
                            className=" mt-3"
                            style={{
                              overflowX: "auto",
                              background: "rgb(209, 211, 255)",
                              boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                              borderRadius: "0.75rem",
                              overflow: "hidden",
                              fontFamily: "DM sans-serif",
                              padding: "20px",
                            }}
                          >
                            <div>
                              <h4>{program.program_Name}</h4>
                              <h6
                                style={{ marginTop: "-30px" }}
                                className="float-end"
                              >
                                <Link
                                  to={`/programdetails/${program.program_id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "var(--Primary-1, #565ADD)",
                                    textAlign: "right",
                                    fontFamily: "Noto Sans",
                                    fontSize: "18px",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "normal",
                                  }}
                                >
                                  View Details
                                </Link>
                              </h6>
                              <hr />
                            </div>
                            <div>
                              <Row>
                                <Col md="4" sm="6">
                                  <h6
                                    style={{
                                      color: "var(--Grey-900, #2C2C2C)",
                                      fontFamily: "Noto Sans",
                                      fontSize: "20px",
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                      lineHeight: "normal",
                                    }}
                                  >
                                    Code
                                  </h6>
                                  <p
                                    style={{
                                      color: "var(--grey-500, #6B6B6B)",
                                      fontFamily: "Noto Sans",
                                      fontSize: "18px",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      lineHeight: "normal",
                                    }}
                                  >
                                    {program.code}
                                  </p>
                                  <img
                                    className="mx-2"
                                    src={chip}
                                    width="80px"
                                    alt="Chip"
                                  />
                                </Col>
                                <Col md="8" sm="12">
                                  <h6
                                    style={{
                                      color: "var(--Grey-900, #2C2C2C)",
                                      fontFamily: "Noto Sans",
                                      fontSize: "20px",
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                      lineHeight: "normal",
                                    }}
                                  >
                                    Description
                                  </h6>
                                  <p
                                    style={{
                                      color: "var(--grey-500, #6B6B6B)",
                                      fontFamily: "Noto Sans",
                                      fontSize: "18px",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      lineHeight: "normal",
                                    }}
                                  >
                                    {program.description}
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Row>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
