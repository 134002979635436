import React, { useState } from 'react'
import { Row, Col, Button, Card } from 'react-bootstrap'
import { Form, FormControl } from 'react-bootstrap'
import { Link, Outlet,useNavigate   } from 'react-router-dom'
import {IoIosArrowBack} from 'react-icons/io'
import DataService from "../../Components/services/requestApi";
import TiersModal from './TiersModal'
import Swal from 'sweetalert2'
// import { useHistory } from 'react-router-dom';

const Createnewprogram = () => {
    const [tierModalOpen, setTierModalOpen] = useState(false);
 
    const [formData, setFormData] = useState({
        program_Name: '',
        code: '',
        point_name: '',
        point_type: '',
        description: '',
        qualification: '',
        qualifying_entries: '',
        point_accrual_factor: '',
        point_redemption_factor: '',
        point_expiration_policy: '',
        point_expiration_period: '',
        tier_code: '',
        tier_name: '',
        previous_tier_code: '',
        qualification_period: '',
        tier_award_period: '',
        next_tier_code: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const { client_id } = JSON.parse(localStorage.getItem("User_data"));

    const handleCreateLoyaltyProgram = async () => {
        try {
            const response = await DataService.createNewLoylityProgram(client_id, formData);
            Swal.fire({
                title: response.data.message,
                icon: 'success',
                timer: 1500,
            });
            handleback()
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const navigate = useNavigate();

    function handleback() {
      navigate("/loyalty");
    }
  
console.log("first". handleback)
    return (
        <>
        <div className='bg-light 'style={{ minHeight: '100vh' }}>
            <div className='container p-4 '>
                <Row>

                    <Col xs={12} sm={10}>
                        <h4><Link to="/loyalty" style={{ color: 'black' }}>
                                        <IoIosArrowBack />
                                    </Link>
                            <b className='mx-3' >New Program</b>
                        </h4>

                    </Col>

                </Row>
            </div>
          <Form >
            <Card className='p-3 container mt-1'>
                <Row>

                    <Col xs={12} sm={10}>
                        <h5>
                        <b>General </b> 
                        </h5>
                       

                    </Col>
                    <hr/>
                </Row>
                <Row>
                <Col xs={12} sm={6}>
              <Form>
                <Form.Label>Program Name</Form.Label>
                <FormControl
                  name="program_Name"
                  value={formData.program_Name}
                  onChange={handleChange}
                  placeholder="Gold Program"
                />
                <Form.Group className="mt-3">
                  <Form.Label>Point Type</Form.Label>
                  <Form.Select
                    name="point_type"
                    value={formData.point_type}
                    onChange={handleChange}
                  >
                    <option value="Amount">Amount</option>
                    <option value="Standard"> Standard</option>
                    <option value="300">300</option>
                    <option value="400">400</option>
                  </Form.Select>
                </Form.Group>
                <Form.Label className="mt-3">Program Description</Form.Label>
              
                <FormControl
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Description"
                />
              </Form>
            </Col>
            <Col>
              <Form>
                <Form.Label>Code</Form.Label>
                <FormControl
                  name="code"
                  value={formData.code}
                  onChange={handleChange}
                  placeholder="GPT6234"
                />
                <Form.Label className="mt-3">Point Name</Form.Label>
                <FormControl
                  name="point_name"
                  value={formData.point_name}
                  onChange={handleChange}
                  placeholder="Loyalty Points "
                />
              </Form>
            </Col>
                </Row>
                <Row className='mt-4'>

                    <Col xs={12} sm={10}>
                        <h5>
                       <b>Policies</b> 
                        </h5>
                    

                    </Col>
                    <hr/>
                </Row>
                <Row>
                <Col xs={12} sm={6}>
              <Form>
                <Form.Group>
                  <Form.Label>Qualification</Form.Label>
                  <Form.Select
                    name="qualification"
                    value={formData.qualification}
                    onChange={handleChange}
                  >
                    <option value="Amount">Amount</option>
                    <option value="Standard Qualification">Standard Qualification</option>
                    <option value="300">300</option>
                    <option value="400">400</option>
                  </Form.Select>
                </Form.Group>
                <Form.Label className="mt-3">Point Accrual Factor</Form.Label>
                <FormControl
                  name="point_accrual_factor"
                  value={formData.point_accrual_factor}
                  onChange={handleChange}
                  placeholder="0"
                />
                <Form.Label className="mt-3">Point Expiration Policy</Form.Label>
                <FormControl
                  name="point_expiration_policy"
                  value={formData.point_expiration_policy}
                  onChange={handleChange}
                  placeholder="0"
                />
              </Form>
            </Col>
            <Col>
              <Form>
                <Form.Label>Qualification Entries</Form.Label>
                <FormControl
                  name="qualifying_entries"
                  value={formData.qualifying_entries}
                  onChange={handleChange}
                  placeholder="0"
                />
                <Form.Label className="mt-3">Point Redemption Policy</Form.Label>
                <FormControl
                  name="point_redemption_factor"
                  value={formData.point_redemption_factor}
                  onChange={handleChange}
                  placeholder="0"
                />
                <Form.Group>
                  <Form.Label className="mt-3">Point Expiration Period</Form.Label>
                  <Form.Select
                    name="point_expiration_period"
                    value={formData.point_expiration_period}
                    onChange={handleChange}
                  >
                    <option value="option1">1 year</option>
                    <option value="option2">2 Year</option>
                    <option value="option3">3 Year</option>
                    <option value="option4">4 Year</option>
                  </Form.Select>
                </Form.Group>
              </Form>
            </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12} sm={9}>

                    </Col>
                    <Col >
                        <Button  onClick={handleback} variant="none" style={{ border: "solid lightgrey 1px" }}>
                          Cancel</Button>
                        <Button className='ms-3' style={{  borderRadius: '10px',
  background: 'var(--Primary-1, #565ADD)',
  boxShadow: '0px 3px 13px 0px rgba(57, 57, 57, 0.07)',}}><span  onClick={()=>setTierModalOpen(true)} style={{textDecoration:"none",color:"white"}}>Proceed to Add Tiers </span></Button>
                    </Col>
                </Row>

            </Card>
            </Form> 
        </div>
        <TiersModal handleChange={handleChange} formData={formData} setFormData={setFormData} handleCreateLoyaltyProgram={handleCreateLoyaltyProgram} show={tierModalOpen} onHide={() => setTierModalOpen(false)}/>
        </>

    )
}

export default Createnewprogram