import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Label, Input, Button, Form } from "reactstrap";
import { BiChevronDown, BiChevronLeft } from "react-icons/bi";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import "./index.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { handleCityDetailDropdownRequest, handleBaseCurrencyDropdownRequest, handleLocationDetailDropdownRequest } from "../../../redux/actions-reducers/ComponentProps/ComponentPropsManagement"
import { useNavigate } from "react-router-dom";
import DataService from "../../services/requestApi";
import Swal from "sweetalert2";
import { BACKEND_URL } from "../../utils/constant";
const obj = {
  customer_id: "",
  client_id: "1234",
  suffix: "",
  customer_name: "",
  gender: "",
  dob: "",
  marital_status: "",
  anniversary_date: "",
  mobile_number: "",
  email_id: "",
  address_line1: "",
  address_line2: "",
  city: "",
  country: "",
  pincode: "",
  location: "",
  nationality: "",
  base_currency: "",
  business_created_date: "",
  language: "",
};

const MemberEnrollment = () => {
  const { country_code_dropdown, to_dropdown, gender_dropdown, nationality_dropdown, language_dropdown, marital_status_dropdown, city_dropdown, country_dropdown, base_currency, location_dropdown } = useSelector(e => e.ComponentPropsManagement)
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(obj)));
  const [countryCode, setCountryCode] = useState("");
  const [loginData, setLoginData] = useState("");

  const login_data = localStorage.getItem("login_data");
  useEffect(() => {
    if (login_data) {
      setLoginData(JSON.parse(login_data));
    }
  }, [login_data]);

  const URL = `${BACKEND_URL}/loyalty/customer`;

  const handleReset = () => {
    setCountryCode("");
    setFormData(JSON.parse(JSON.stringify(obj)));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = JSON.parse(JSON.stringify(formData));
    data.mobile_number = `${countryCode}${data.mobile_number}`;
    console.log("data", data);

    // Use DataService to make API request
    DataService.enrollMember(formData)
      .then((res) => {
        if (res.data && res.data.message) {
          // Display a success alert
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: res.data.message,
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          // Display an error alert
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        }
      });
  };


  const inlineStyles = {
    color: "var(--grey-700, #2F2F2F)",
    fontFamily: "Noto Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate("/CustomerComponent-app"); // This function navigates back in the history stack
  };
  return (
    <>
      <div className="bg-light">
    <div className='container'>
        <div className="p-3 d-flex ">
        <BiChevronLeft size={35} className='' onClick={handleGoBack} />
<Row className="">
    
      <h4>
            <span className="m-3"  style={{inlineStyles}}>MemberEnrollment</span>
          </h4>
        
     
        </Row>
     
        </div>
      </div >
    <div className="container bg-white " 
         style={{  overflowY: "scroll",
         boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
         borderRadius: "10px",
         overflow: "hidden",
         fontFamily: "DM sans-serif",
         padding: "20px",}}>
        <Form onSubmit={handleSubmit} className="">
          <Row className="">
            
            <Col md={12}>
              <div className="perc32">PERSONAL INFO</div>
            </Col>
            <hr/>
            <Col md={3}>
              <FormGroup>
                <Label>
                  Title <span className="text-red"> * </span>
                </Label>
                <Select
                  options={to_dropdown}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, suffix: val });
                  }}
                  // value={formData.suffix}
                  required={true}
                  placeholder="Select Mr."
                />
              </FormGroup>
            </Col>


            <Col md={3}>
              <FormGroup>
                <Label>
                  Customer Name <span className="text-red"> * </span>
                </Label>
                <Input
                  type="text"
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormData({ ...formData, customer_name: val });
                  }}
                  value={formData.customer_name}
                  required={true}
                  placeholder="Enter Customer Name"
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Customer ID</Label>
                <Input
                  type="text"
                  onChange={e => {
                    const val = e.target.value
                    setFormData({ ...formData, customer_id: val });
                  }}
                  value={formData.customer_id}
                  placeholder="Enter Customer Id"
                />
                {/* <h3>{loginData?.username}</h3> */}
              </FormGroup>
            </Col>

            {/* <Col md={3}>
              <FormGroup>
                <Label>
                  Client ID
                  <span className="text-red"> * </span>
                </Label>
                <h3>{loginData?.client_id}</h3>
              </FormGroup>
            </Col> */}

<Col md={3}>
              <FormGroup>
                <Label>
                  DOB <span className="text-red"> * </span>
                </Label>
                <Flatpickr
                  className="form-control"
                  options={{ dateFormat: "d-M-Y", allowInput: true }}
                  onChange={(date) => {
                    const val = date[0];
                    setFormData({ ...formData, dob: val });
                  }}
                  value={formData.dob}
                  required={true}
                  placeholder="Select Birth Date"
                />
              </FormGroup>
            </Col>


            <Col md={3}>
              <FormGroup>
                <Label>
                  Gender <span className="text-red"> * </span>
                </Label>
                <Select
                  options={gender_dropdown}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, gender: val });
                  }}
                  // value={formData.gender}
                  required={true}
                  placeholder="Select Gender"
                />
              </FormGroup>
            </Col>

          

            <Col md={3}>
              <FormGroup>
                <Label>
                  Marital Status <span className="text-red"> * </span>
                </Label>
                <Select
                  options={[
                    { label: "Singal", value: "Singal" },
                    { label: "Married", value: "Married" },
                  ]}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, marital_status: val });
                  }}
                  //   value={formData.language}
                  required={true}
                  placeholder="Select Marital Status"
                />
          
              </FormGroup>
            </Col>


            <Col md={3}>
              <FormGroup>
                <Label>
                  Language <span className="text-red"> * </span>
                </Label>
                <Select
                  options={[
                    { label: "English", value: "English" },
                    { label: "Hindi", value: "Hindi" },
                  ]}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, language: val });
                  }}
                  //   value={formData.language}
                  required={true}
                  placeholder="Select Language"
                />
              </FormGroup>
            </Col>
            
            <Col md={3}>
              <FormGroup>
                <Label>
                  Anniversary Date <span className="text-red"> * </span>
                </Label>
                <Flatpickr
                  className="form-control"
                  options={{ dateFormat: "d-M-Y", allowInput: true }}
                  onChange={(date) => {
                    const val = date[0];
                    setFormData({ ...formData, anniversary_date: val });
                  }}
                  value={formData.anniversary_date}
                  required={true}
                  placeholder="Select Anniversary Date"
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>
                  Phone Number <span className="text-red"> * </span>
                </Label>
                <div className="d-flex">
                  <div className=" w-50">
                    <Select
                      options={country_code_dropdown}
                      onChange={(e) => {
                        const val = e.value;
                        setCountryCode(val);
                      }}
                      value={country_code_dropdown.filter(io => io.value === countryCode)}
                      required={true}
                      placeholder="+91"
                    />
                  </div>
                  <div className="w-55">
                    <Input
                      type="text"
                      onChange={(e) => {
                        const val = e.target.value;
                        setFormData({ ...formData, mobile_number: val });
                      }}
                      value={formData.mobile_number}
                      required={true}
                      placeholder="Enter Phone Number"
                    />
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Label>
                  Email <span className="text-red"> * </span>
                </Label>
                <Input
                  type="text"
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormData({ ...formData, email_id: val });
                  }}
                  value={formData.email_id}
                  required={true}
                  placeholder="Enter Email"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="perc32">Address Info</div>
            </Col>
            <hr className=""/>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Address Line 1 <span className="text-red"> * </span>
                </Label>
                <Input
                  type="text"
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormData({ ...formData, address_line1: val });
                  }}
                  value={formData.address_line1}
                  required={true}
                  placeholder="Enter Address Line 1"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Address Line 2 <span className="text-red"> * </span>
                </Label>
                <Input
                  type="text"
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormData({ ...formData, address_line2: val });
                  }}
                  value={formData.address_line2}
                  required={true}
                  placeholder="Enter Address Line 2"
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  Country <span className="text-red"> * </span>
                </Label>
                <Select
                 
                  options={country_dropdown}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, country: val });
                  }}
                  value={country_dropdown.filter(io => io.value === formData.country)}
                  required={true}
                  placeholder="Select Country"
                  menuPlacement="top"
                  
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>
                  Postal Code <span className="text-red"> * </span>
                </Label>
                <Input
                  type="number"
                  onChange={(e) => {
                    const val = e.target.value;
                    setFormData({ ...formData, pincode: val });
                  }}
                  value={formData.pincode}
                  required={true}
                  placeholder="Enter Postal Code"
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  City <span className="text-red"> * </span>
                </Label>
                <Input
  type="text"
  onChange={(e) => setFormData({ ...formData, city: e.target.value })}
  value={formData.city}
  required={true}
  placeholder="Enter City"
/>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  Location <span className="text-red"> * </span>
                </Label>
                <Input
  type="text"
  onChange={(e) => setFormData({ ...formData, location: e.target.value })}
  value={formData.location}
  required={true}
  placeholder="Enter Location"
/>

              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>
                  Nationality <span className="text-red"> * </span>
                </Label>
                <Select
                options={nationality_dropdown}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, nationality: val });
                  }}
                  //   value={formData.language}
                  required={true}
                  placeholder="Nationality"
                  menuPlacement="top"
                />
                {/* <Select
                  options={[]}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, nationality: val });
                  }}
                  value={formData.nationality}
                  required={true}
                  placeholder="Select Nationality"
                /> */}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  Base Currency <span className="text-red"> * </span>
                </Label>
                <Select
                  type="text"
                  options={base_currency}
                  onChange={(e) => {
                    const val = e.value;
                    setFormData({ ...formData, base_currency: val });
                  }}
                  value={base_currency.filter(io => io.value === formData.base_currency)}
                  required={true}
                  menuPlacement="top"
                  placeholder="Select Base Currency"
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>
                  Business Created Date <span className="text-red"> * </span>
                </Label>
                <Flatpickr
                  className="form-control"
                  options={{ dateFormat: "d-M-Y", allowInput: true }}
                  onChange={(date) => {
                    const val = date[0];
                    setFormData({ ...formData, business_created_date: val });
                  }}
                  value={formData.business_created_date}
                  required={true}
                  placeholder="Select Business Created Date"
                />
              </FormGroup>
            </Col>

        

            <Col className="mt-5" md={3}>
              <div className="d-flex justify-content-end">
                <Button
                  type="button"
                  className="lightButton me-2"
                  style={{  borderRadius: '10px',
                  border: '1px solid var(--grey-500, #6B6B6B)',
                  boxShadow: '0px 3px 13px 0px rgba(57, 57, 57, 0.07)'}}
                  onClick={() => handleReset()}
                >
                  Reset
                </Button>

                <Button
                  type="submit"
                  style={{  borderRadius: '10px',
                  background: 'var(--Primary-1, #565ADD)',
                  boxShadow: '0px 3px 13px 0px rgba(57, 57, 57, 0.07)'}}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div> </div>
    </>
  );
};

export default MemberEnrollment;
