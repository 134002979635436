import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Form, FormControl } from 'react-bootstrap';
import DataService from "../../Components/services/requestApi";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
const General = ({ program,program_id }) => {
    // State to manage the form data
    const [formData, setFormData] = useState({
        programName: program.program_Name,
        pointType: program.point_type,
        description: program.description,
        code: program.code,
        pointName: program.point_name
    });

    // Handle input change for form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const navigate = useNavigate()
    const { client_id } = JSON.parse(localStorage.getItem("User_data"));
     const handleEnterClick = async () => {
    try {
      const data = {
        program_name: formData.programName,
        code: formData.code,
        point_name: formData.pointName,
        point_type: formData.pointType,
        description: formData.description
      };
      const response = await DataService.updateGeneralByCode(client_id, program_id, data);
        Swal.fire({
          title: response.data.message,
          icon: "success",
          timer: 1500,
        });
        navigate('/loyalty');
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 

      function handleback() {
        navigate("/loyalty");
      }
    return (
        <div>
            <div className="bg-white container mb-3"
                style={{
                    overflowX: "auto",
                    boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                    borderRadius: "0.75rem",
                    overflow: "hidden",
                    fontFamily: "DM sans-serif",
                    padding: "20px",
                }}>
                <div>
                    <h4> General</h4>
                    <hr />
                </div>
                <Row>
                    <Col xs={12} sm={6}>
                        <Form>
                            <Form.Label>Program Name</Form.Label>
                            <FormControl
                                name="programName"
                                value={formData.programName}
                                onChange={handleChange}
                                placeholder="Gold Program"
                            />

                            <Form.Group className="mt-3">
                                <Form.Label>Point Type</Form.Label>
                                <Form.Select
                                    name="pointType"
                                    value={formData.pointType}
                                    onChange={handleChange}
                                >
                                    <option value="amount">Amount</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                    <option value="option4">Option 4</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Label className="mt-3">Program Description</Form.Label>
                            <div className="border border-grey rounded" style={{ padding: '10px' }}>
                                <FormControl
                                    as="textarea"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    placeholder="Description"
                                />
                            </div>
                        </Form>
                    </Col>
                    <Col>
                        <Form>
                            <Form.Label>Code</Form.Label>
                            <FormControl
                                name="code"
                                value={formData.code}
                                onChange={handleChange}
                                placeholder="GPT6234"
                            />

                            <Form.Label className="mt-3">Point Name</Form.Label>
                            <FormControl
                                name="pointName"
                                value={formData.pointName}
                                onChange={handleChange}
                                placeholder="Loyalty Points"
                            />
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={10}></Col>
                    <Col>
                        <Button onClick={handleback}  variant="none" style={{ border: "solid lightgrey 1px" }}>Cancel</Button>
                        <Button className="ms-3" onClick={handleEnterClick} style={{ borderRadius: '10px', background: 'var(--Primary-1, #565ADD)', boxShadow: '0px 3px 13px 0px rgba(57, 57, 57, 0.07)' }}>Save</Button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default General;
