import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { BiSearchAlt2 } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { AiFillEye, AiFillEyeInvisible, AiOutlineArrowLeft } from 'react-icons/ai';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Label } from 'reactstrap';
import { FormGroup } from 'react-bootstrap';
function PasswordChangeModal(props) {
    const [showPassword, setShowPassword] = useState(false);
  return(
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
                    <Modal.Body>
      <Row>
        <Col>
          <div className='d-flex'>
            <AiOutlineArrowLeft className='fs-4' style={{ cursor: "pointer" }} onClick={() => props.onHide()} />
            <h4 className='mx-3'>Change Password</h4>
          </div>
          <Row className=''>
            <Col className="mt-2">
              <FormGroup>
                <Label>Old Password <span className="text-red"> * </span></Label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="**********"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="mt-2">
              <FormGroup>
                <Label>New Password<span className="text-red"> * </span></Label>
                <div className="password-input-wrapper">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                  />
                  <span
                    className="password-toggle-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {/* {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />} */}
                  </span>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="mt-2">
              <FormGroup>
                <Label>Confirm New Password<span className="text-red"> * </span></Label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="**********"
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="mt-4" md={12}>
          <div className="mt-2 d-flex justify-content-end">
            <Button type="button" className="lightButton me-2" onClick={() => props.onHide()}>
              Cancel
            </Button>
            <Button type="submit"  style={{  borderRadius: '10px',
                  background: 'var(--Primary-1, #565ADD)',
                  boxShadow: '0px 3px 13px 0px rgba(57, 57, 57, 0.07)'}}>
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </Modal.Body>
    </Modal>
  );
}

export default PasswordChangeModal;
