import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import TiersModal from './TiersModal';
import TiersModaltwo from './TiersEditModal';
import { BiPencil } from 'react-icons/bi';
import { BiSearchAlt2, BiUser } from 'react-icons/bi';
import TiersEditModal from './TiersEditModal';
import AddnewuserTiersModal from './AddnewuserTiersModal';
const Tiers = ({ program , setProgram,program_id}) => {
    const [tierModalOpen, setTierModalOpen] = useState(false);
    const [adduserModalOpen, setAdduserModalOpen] = useState(false);

    return (
        <>
        <div className='h-screen w-100'>
            <div className="bg-white container mb-3"
                style={{
                    overflowX: "auto",
                    boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                    borderRadius: "0.75rem",
                    overflow: "hidden",
                    fontFamily: "DM sans-serif",
                    padding: "20px",
                }}>
                <div>
                    <h4><b>Tiers</b></h4>
                    <hr />
                </div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Previous Tier Code</th>
                            <th>Qualification Period</th>
                            <th>Tier Award Period</th>
                            <th>Next Tier Code</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                                <td>{program?.tier_code}</td>
                                <td>{program?.tier_name}</td>
                                <td>{program?.previous_tier_code}</td>
                                <td>{program?.qualification_period}</td>
                                <td>{program?.tier_award_period}</td>
                                <td>{program?.next_tier_code}</td>
                                <td className='cursor-pointer' ><span className='cursor-pointer' onClick={()=>setTierModalOpen(true)}><BiPencil style={{ color: 'red', cursor:"pointer" }} className='cursor-pointer' /></span>
                                <span className='cursor-pointer' onClick={()=>setAdduserModalOpen(true)}><BiUser style={{ color: 'green',cursor:"pointer" }} className='cursor-pointer' /></span>
                                </td>


                         
                    </tbody>
                </Table>
            </div>
        </div>
        <TiersEditModal program_id={program_id} setProgram={setProgram} program={program} show={tierModalOpen} onHide={() => setTierModalOpen(false)}/>
        <AddnewuserTiersModal program_id={program_id}  program={program} show={adduserModalOpen} onHide={() => setAdduserModalOpen(false)}/>

        </>
    );
}

export default Tiers;
