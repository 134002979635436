import axios from "axios";
import { BACKEND_URL } from '../utils/constant';

export const BASEURL = {
    ENDPOINT_URL: `${BACKEND_URL}`,
}
export const authToken = localStorage.getItem('token');
export default axios.create({
  baseURL: `${BASEURL.ENDPOINT_URL}`,
  // headers: {
  //     "Access-Control-Allow-Origin":"*",
  //     "authtoken": `${authToken}`
  // }
});